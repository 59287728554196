import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { defaultLanguage } from '../../../i18n'
import { loginFormApi } from '../../../services/login-form.api'

interface INavbarInitialState {
  defaultLanguage: string
  languageToNotLoggedInUser: boolean
  loadedDefaultLanguage: boolean
}

const initialState: INavbarInitialState = {
  defaultLanguage,
  languageToNotLoggedInUser: false,
  loadedDefaultLanguage: false,
}

export const asyncActions = {
  PREFERENCELANGUAGE: createAsyncThunk(
    'PREFERENCELANGUAGE',
    async (language: any) => {
      await loginFormApi.languagePost(language)
      return language
    }
  ),
}

export const NavbarSlice = createSlice({
  name: 'navbarSlice',
  initialState,
  reducers: {
    CHANGE_DEFAULT_LANGUAGE: (state, action) => {
      state.defaultLanguage = action.payload
    },
    LOAD_USER_PREFERENCE_LANGUAGE: (state, action) => {
      state.defaultLanguage = action.payload
      state.loadedDefaultLanguage = true
    },
    SET_LOADED_DEFAULT_LANGUAGE_AFTER_FAILED_LOGIN: (state) => {
      state.loadedDefaultLanguage = true
    },
  },
  extraReducers: (builder) => [
    builder.addCase(
      asyncActions.PREFERENCELANGUAGE.fulfilled,
      (state, action) => {
        state.defaultLanguage = action.payload
        state.languageToNotLoggedInUser = true
      }
    ),
  ],
})
