import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Row, Col, Divider, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Header } from '../../components/Header/Header';

import LeftColumn from '../../assets/about_left_image_2.jpg';
import RightColumn from '../../assets/about_right_image_3.jpg';

import './About.scss';
import { RootState } from '../../store/store';

export const About = () => {
  const loginState = useSelector((state: RootState) => state.login);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToIngenicoWebSite = () => {
    window.open('https://www.ingenico.com.br/');
  };

  const goToWebRepository = () => {
    if (!loginState.user) {
      return navigate('/login?repositoryRedirect');
    }

    return navigate('/repositories');
  };

  return (
    <Col push={1} span={22} pull={1} id="about-id">
      <div className="About-page">
        <Header title={t('about-page-header-text')} showEllipsis>
          <Fragment>
            <Row>
              <Col span={12} className="about-page-image-col-style">
                <img
                  src={LeftColumn}
                  alt=""
                  className="about-page-left-image-style"
                />
              </Col>
              <Col span={12}>
                <h1 className="about-page-h1-style">
                  {t('ingenico-group-title')}
                </h1>
                <p>{t('ingenico-group-first-paragraph')}</p>
                <p>{t('ingenico-group-second-paragraph')}</p>
                <p>{t('ingenico-group-third-paragraph')}</p>
                <Row>
                  <Col>
                    <Button
                      id="about-button-learn-more"
                      type="primary"
                      ghost
                      shape="round"
                      onClick={goToIngenicoWebSite}
                    >
                      {t('ingenico-group-button-text')} <RightOutlined />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Divider className="about-page-divider-style" />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <h1 className="about-page-h1-style">
                  {t('innovation-at-ingenico-title')}
                </h1>
                <p>{t('innovation-at-ingenico-paragraph')}</p>
                <Divider />
                <h1 className="about-page-h1-style">
                  {t('what-is-ingenicos-portal-title')}
                </h1>
                <p>{t('what-is-ingenicos-portal-paragraph')}</p>
                <Divider />
                <h1 className="about-page-h1-style">
                  {t('our-solutions-title')}
                </h1>
                <p>
                  <span
                    id="about-button-created-pages"
                    className="about-page-click-here-style"
                    onClick={() => {
                      navigate('/created-pages');
                    }}
                  >
                    {t('our-solutions-click-here-button-text')}
                  </span>
                  {t('our-solutions-first-paragraph')}
                </p>
                <p>
                  <span
                    id="about-button-web-repository"
                    className="about-page-click-here-style"
                    onClick={goToWebRepository}
                  >
                    {t('our-solutions-click-here-button-text')}
                  </span>{' '}
                  {t('our-solutions-second-paragraph')}
                </p>
                <Divider />
                <h1 className="about-page-h1-style">
                  {t('who-has-access-to-the-portal-title')}
                </h1>
                <p>{t('who-has-access-to-the-portal-first-paragraph')}</p>
                <p>{t('who-has-access-to-the-portal-second-paragraph')}</p>
              </Col>
              <Col span={12} className="about-page-image-col-style">
                <img
                  src={RightColumn}
                  alt=""
                  className="about-page-right-image-style"
                />
              </Col>
            </Row>
          </Fragment>
        </Header>
      </div>
    </Col>
  );
};
