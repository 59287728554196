import React, { Fragment, useEffect, useState } from 'react';
import { Header } from '../../components/Header/Header';
import { Button, Col, Divider, Modal, Row, Spin, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { asyncActions, TagsPanelSlice } from './TagsPanel.Slice';
import { uniqueId } from 'lodash';
import queryString from 'query-string';
import './TagsPanel.scss';

import { ReactComponent as RedBar } from '../../assets/red_bar_icon.svg';
import { TableWithEditAndExpansion } from './TableWithEditAndExpansion/TableWithEditAndExpansion';
import { showModal } from '../../helpers/showModal/showModal';
import {
  IDataParentTagOptions,
  TagCreateModal,
} from './TagCreateModal/TagCreateModal';
import { IClassification } from '../../services/classification.api';

export interface IDataSource {
  id: number;
  key: number;
  name: string;
  children?: IClassification[];
  enabled: boolean;
  pagesIds: number[] | undefined;
}

export const TagsPanel: React.FC = () => {
  const [dataSourceTable, setDataSourceTable] = useState<IDataSource[]>();
  const [disabledDataSourceTable, setDisabeldDataSourceTable] =
    useState<IDataSource[]>();
  const [dataLevelOneTagOptions, setDataLevelOneTagOptions] =
    useState<IDataParentTagOptions[]>();
  const [dataParentTagOptions, setDataParentTagOptions] =
    useState<IDataParentTagOptions[]>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDisableOrDeleteModalOpen, setIsDisableOrDeleteModalOpen] =
    useState(false);
  const [selectedRecordToDisableOrDelete, setSelectedRecordToDisableOrDelete] =
    useState<IClassification | undefined>(undefined);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const tagsState = useSelector((state: RootState) => state.tagPanel);
  const enabledTagsState = useSelector(
    (state: RootState) => state.tagPanel.enabledTags,
  );
  const disabledTagsState = useSelector(
    (state: RootState) => state.tagPanel.disabledTags,
  );
  const allTagsState = useSelector(
    (state: RootState) => state.tagPanel.allTags,
  );
  const tagPanelState = useSelector((state: RootState) => state.tagPanel);

  const { actions } = TagsPanelSlice;

  const tabKeysList = ['tag-level-one', 'tag-level-two', 'tag-level-three'];
  const parsedUrl = queryString.parse(window.location.search);

  useEffect(() => {
    if (tagsState.isLoading) {
      return;
    }
    if (!tagsState.shouldRefresh) {
      return;
    }

    dispatch(asyncActions.GEL_ALL_TAGS_LIST());
    dispatch(asyncActions.GET_ENABLED_TAGS_LIST());
    dispatch(asyncActions.GET_DISABLED_TAGS_LIST());

    dispatch(TagsPanelSlice.actions.SET_SHOULD_REFRESH(false));
  }, [tagsState]);

  useEffect(() => {
    if (parsedUrl.tab === 'tag-level-three') {
      const mapTagListLevelThree = (list: any, isDisabled: boolean) => {
        if (!Array.isArray(list)) return [];
        return list
          .filter(
            (tag) =>
              tag.subClassifications.length > 0 &&
              tag.subClassifications.some(
                (child: any) => child.subClassifications.length > 0,
              ),
          )
          .map((tag) => ({
            id: tag.id,
            name: tag.name,
            key: tag.id,
            enabled: tag.enabled,
            pagesIds: tag.pagesIds,
            children: tag.subClassifications
              .map((child: any) => ({
                id: child.id,
                name: child.name,
                key: child.id,
                enabled: child.enabled,
                pagesIds: child.pagesIds,
                children: child.subClassifications
                  .filter((item: any) => item.enabled === !isDisabled)
                  .map((item: any) => ({
                    id: item.id,
                    name: item.name,
                    key: item.id,
                    enabled: item.enabled,
                    pagesIds: item.pagesIds,
                  })),
              }))
              .filter((child: any) => child.children.length > 0),
          }))
          .filter((item) => item.children.length > 0);
      };

      setDataSourceTable(mapTagListLevelThree(allTagsState, false));
      setDisabeldDataSourceTable(mapTagListLevelThree(allTagsState, true));

      if (enabledTagsState) {
        const filteredOptions = enabledTagsState.filter(
          (item: any) => item.subClassifications.length > 0,
        );
        const dataParentOptions = filteredOptions?.map((item: any) => {
          return {
            id: item.id,
            value: item.name,
            label: item.name,
            children: item.subClassifications.map((child: any) => ({
              id: child.id,
              value: child.name,
              label: child.name,
            })),
          };
        });
        setDataParentTagOptions(dataParentOptions);
      }
      return;
    }

    if (parsedUrl.tab === 'tag-level-two') {
      const filterAndMapTagListLevelTwo = (list: any, isDisabled: boolean) => {
        if (!Array.isArray(list)) return [];
        return list
          .filter(
            (tag: any) =>
              Array.isArray(tag.subClassifications) &&
              tag.subClassifications.length > 0,
          )
          .map((tag: any) => ({
            id: tag.id,
            name: tag.name,
            key: tag.id,
            enabled: tag.enabled,
            pagesIds: tag.pagesIds,
            children: tag.subClassifications
              .filter((child: any) => child.enabled === !isDisabled)
              .map((child: any) => ({
                id: child.id,
                name: child.name,
                key: child.id,
                enabled: child.enabled,
                pagesIds: child.pagesIds,
              })),
          }))
          .filter((item) => item.children.length > 0);
      };
      setDataSourceTable(filterAndMapTagListLevelTwo(allTagsState, false));
      setDisabeldDataSourceTable(
        filterAndMapTagListLevelTwo(allTagsState, true),
      );

      if (enabledTagsState) {
        const dataLevelOneOptions = enabledTagsState?.map((tag: any) => {
          return { id: tag.id, value: tag.name, label: tag.name };
        });
        if (dataLevelOneOptions) {
          setDataLevelOneTagOptions(dataLevelOneOptions);
        }
      }
      return;
    }
    const mapTagListLevelOne = (list: any, isDisabled: boolean) => {
      if (!Array.isArray(list)) return [];
      return list
        .filter((tag) => tag.enabled === !isDisabled)
        .map((tag: any) => ({
          id: tag.id,
          name: tag.name,
          key: tag.id,
          enabled: tag.enabled,
          pagesIds: tag.pagesIds,
        }));
    };

    setDataSourceTable(mapTagListLevelOne(allTagsState, false));
    setDisabeldDataSourceTable(mapTagListLevelOne(allTagsState, true));
  }, [parsedUrl.tab, enabledTagsState, disabledTagsState]);

  useEffect(() => {
    const tab = tabKeysList.includes(parsedUrl.tab as string)
      ? parsedUrl.tab
      : 'tag-level-one';
    dispatch(TagsPanelSlice.actions.TAB_CHANGE(tab));
  }, [dispatch, parsedUrl.tab, tabKeysList]);

  useEffect(() => {
    return () => {
      dispatch(actions.BACK_TO_ORIGINAL_STATE());
    };
  }, [dispatch, actions]);

  const onTabChange = (activeKey: string) => {
    navigate(`/tags-panel?tab=${activeKey}`);
    dispatch(actions.TAB_CHANGE(activeKey));
  };

  const showDisableOrDeleteModalDisable = (row: any) => {
    setIsDisableOrDeleteModalOpen(true);
    if (parsedUrl.tab === 'tag-level-one') {
      const selectRecord = allTagsState?.find((record) => record.id === row.id);
      setSelectedRecordToDisableOrDelete(selectRecord);
      return;
    }
    if (parsedUrl.tab === 'tag-level-two') {
      const findRecord = allTagsState?.find(
        (item: any) =>
          item.subClassifications &&
          item.subClassifications.find((child: any) => child.id === row.id),
      );
      if (findRecord && findRecord.subClassifications) {
        const selectRecord = findRecord.subClassifications;
        if (selectRecord && selectRecord.length > 0) {
          setSelectedRecordToDisableOrDelete(selectRecord[0]);
        }
      }
      return;
    }

    const findRecord = allTagsState?.find(
      (record) =>
        record.subClassifications &&
        record.subClassifications.find(
          (child) =>
            child.subClassifications &&
            child.subClassifications.find((item) => item.id === row.id),
        ),
    );
    if (findRecord && findRecord.subClassifications) {
      const selectRecord = findRecord.subClassifications[0]?.subClassifications;
      if (selectRecord && selectRecord.length > 0) {
        setSelectedRecordToDisableOrDelete(selectRecord[0]);
      }
    }
  };

  const handleCancelDisable = () => {
    setIsDisableOrDeleteModalOpen(false);
    setSelectedRecordToDisableOrDelete(undefined);
  };

  const dispatchDisableTag = (id: number) => {
    dispatch(asyncActions.DISABLE_TAG(id));
    setIsDisableOrDeleteModalOpen(false);
  };

  const showDisableConfirmationModal = () => {
    let isPossibleToDelete;
    if (selectedRecordToDisableOrDelete) {
      const documentsRelated = selectedRecordToDisableOrDelete.pagesIds?.length
        ? selectedRecordToDisableOrDelete.pagesIds.length >= 1
        : false;
      const subClassifications = selectedRecordToDisableOrDelete
        .subClassifications?.length
        ? selectedRecordToDisableOrDelete.subClassifications.length >= 1
        : false;
      isPossibleToDelete = !documentsRelated && !subClassifications;
    }

    const disableConfirmationModalTitle = isPossibleToDelete
      ? t('tag-panel-disable-or-delete-tag-title')
      : t('tag-panel-disable-tag-title');

    let modalDisableText;
    let modalDeleteText;
    let modalToEnableDelete;
    if (parsedUrl.tab === 'tag-level-three') {
      modalDisableText = t('tag-panel-disable-last-level-tag-subtitle');
      modalDeleteText = t('tag-panel-delete-level-three-tag-subtitle');
      modalToEnableDelete = t(
        'tag-panel-delete-enable-delete-tag-level-three-subtitle',
      );
    } else if (parsedUrl.tab === 'tag-level-two') {
      modalDisableText = t('tag-panel-disable-tag-subtitle');
      modalDeleteText = t('tag-panel-delete-level-two-tag-subtitle');
      modalToEnableDelete = t('tag-panel-delete-enable-delete-tag-subtitle');
    } else {
      modalDisableText = t('tag-panel-disable-tag-subtitle');
      modalDeleteText = t('tag-panel-delete-main-tag-subtitle');
      modalToEnableDelete = t('tag-panel-delete-enable-delete-tag-subtitle');
    }

    const disableConfirmationModalContent = isPossibleToDelete ? (
      <>
        <p>
          <span className="modal-bold-text">
            {t('tag-panel-disable-pop-up-disable-button')}:{' '}
          </span>
          {modalDisableText}
        </p>
        <p>
          <span className="modal-bold-text">
            {t('tag-panel-disable-pop-up-delete-button')}:{' '}
          </span>
          {t('tag-panel-delete-warning-delete-tag-subtitle')} {modalDeleteText}
        </p>
      </>
    ) : (
      <>
        <p>{modalDisableText}</p>
        <p>
          <span className="modal-bold-text">
            {t('tag-panel-disable-pop-up-delete-button')}:{' '}
          </span>
          {modalToEnableDelete}
        </p>
      </>
    );

    return (
      <Modal
        title={disableConfirmationModalTitle}
        open={isDisableOrDeleteModalOpen}
        onCancel={handleCancelDisable}
        centered
        footer={
          isPossibleToDelete
            ? [
                <Button key="cancel" onClick={handleCancelDisable}>
                  {t('tag-panel-disable-pop-up-cancel-button')}
                </Button>,
                <Button
                  key="disable"
                  type="primary"
                  onClick={() => {
                    if (selectedRecordToDisableOrDelete) {
                      dispatchDisableTag(selectedRecordToDisableOrDelete?.id);
                    }
                  }}
                >
                  {t('tag-panel-disable-pop-up-disable-button')}
                </Button>,
                <Button
                  key="delete"
                  type="primary"
                  onClick={() => {
                    if (selectedRecordToDisableOrDelete) {
                      dispatch(
                        asyncActions.DELETE_TAG(
                          selectedRecordToDisableOrDelete?.id,
                        ),
                      );
                    }
                    setIsDisableOrDeleteModalOpen(false);
                  }}
                >
                  {t('tag-panel-disable-pop-up-delete-button')}
                </Button>,
              ]
            : [
                <Button key="cancel" onClick={handleCancelDisable}>
                  {t('tag-panel-disable-pop-up-cancel-button')}
                </Button>,
                <Button
                  key="disable"
                  type="primary"
                  onClick={() => {
                    if (selectedRecordToDisableOrDelete) {
                      dispatchDisableTag(selectedRecordToDisableOrDelete?.id);
                    }
                  }}
                >
                  {t('tag-panel-disable-pop-up-disable-button')}
                </Button>,
              ]
        }
      >
        {disableConfirmationModalContent}
      </Modal>
    );
  };

  const showEnableConfirmationModal = (id: number) => {
    let subtitleModal =
      parsedUrl.tab === 'tag-level-three'
        ? t('tag-panel-enable-tag-level-three-subtitle')
        : t('tag-panel-enable-main-tag-subtitle');

    return showModal(
      'warning',
      t('tag-panel-enable-tag-title'),
      subtitleModal,
      () => {
        dispatch(asyncActions.ENABLE_TAG(id));
      },
      () => {},
      t('tag-panel-enable-pop-up-enable-button'),
      t('tag-panel-enable-pop-up-cancel-button'),
    );
  };

  const showModalOpen = () => {
    setIsCreateModalOpen(true);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const showCreateTagModal = () => {
    const isLevelTwoTag = parsedUrl.tab === 'tag-level-two';
    const isLevelThreeTag = parsedUrl.tab === 'tag-level-three';
    return (
      <TagCreateModal
        isOpen={isCreateModalOpen}
        setIsModalOpen={setIsCreateModalOpen}
        title={t('tag-panel-create-new-tag-label')}
        okText={t('tag-panel-pop-up-save-button')}
        cancelText={t('tag-panel-pop-up-cancel-button')}
        handleCancel={handleCancel}
        dataLevelOneTagOptions={dataLevelOneTagOptions}
        dataParentTagOptions={dataParentTagOptions}
        isLevelTwoTag={isLevelTwoTag}
        isLevelThreeTag={isLevelThreeTag}
        requiredText={t('tag-panel-required-field-label')}
        onCreateTag={(values: any, selectedId: any) => {
          if (isLevelTwoTag) {
            dispatch(
              asyncActions.CREATE_NEW_TAG({
                name: values.name.trim(),
                parent: {
                  id: selectedId,
                },
                level: 2,
              }),
            );
            return;
          }
          if (isLevelThreeTag) {
            dispatch(
              asyncActions.CREATE_NEW_TAG({
                name: values.name.trim(),
                parent: {
                  id: selectedId,
                },
                level: 3,
              }),
            );
            return;
          }
          dispatch(
            asyncActions.CREATE_NEW_TAG({
              name: values.name.trim(),
              level: 1,
            }),
          );
        }}
      />
    );
  };

  const handleEditTag = (id: number, newName: string) => {
    dispatch(
      asyncActions.RENAME_TAG({
        id,
        newName,
      }),
    );
  };

  const showTagsTable = (buttonLabel: string) => {
    return (
      <Fragment>
        <Row>
          <Col span={24}>
            <Button
              id="new-tag-button"
              className="new-tag-button"
              onClick={() => showModalOpen()}
            >
              {buttonLabel}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Row style={{ marginTop: '2rem' }}>
              <RedBar />
            </Row>
            <Row>
              <h2>{t('tag-panel-enabled-tag-table-title')}</h2>
            </Row>
            <TableWithEditAndExpansion
              dataSource={dataSourceTable}
              isDisabledTable={false}
              numberOfItems={dataSourceTable?.length}
              handleEditTag={handleEditTag}
              showDisableOrDeleteModalDisable={showDisableOrDeleteModalDisable}
              showDisableConfirmationModal={showDisableConfirmationModal}
              showEnableConfirmationModal={() => {}}
            />
          </Col>
        </Row>
        <Row>
          <Divider />
        </Row>
        <Row>
          <Col span={24}>
            <Row style={{ marginTop: '2rem' }}>
              <RedBar />
            </Row>
            <Row>
              <h2>{t('tag-panel-disabled-tag-table-title')}</h2>
            </Row>
            <TableWithEditAndExpansion
              dataSource={disabledDataSourceTable}
              isDisabledTable={true}
              numberOfItems={disabledDataSourceTable?.length}
              handleEditTag={() => {}}
              showDisableConfirmationModal={() => {}}
              showDisableOrDeleteModalDisable={() => {}}
              showEnableConfirmationModal={showEnableConfirmationModal}
            />
          </Col>
        </Row>
        {showCreateTagModal()}
        {showDisableConfirmationModal()}
      </Fragment>
    );
  };

  const renderTabContent = () => {
    switch (tagPanelState.currentTab) {
      case 'tag-level-one': {
        const tagLevelOneLabel = t('tag-panel-new-level-one-tag-button-label');
        return showTagsTable(tagLevelOneLabel);
      }
      case 'tag-level-two': {
        const tagLevelTwoLabel = t('tag-panel-new-level-two-tag-button-label');
        return showTagsTable(tagLevelTwoLabel);
      }
      case 'tag-level-three': {
        const tagLevelThreeLabel = t(
          'tag-panel-new-level-three-tag-button-label',
        );
        return showTagsTable(tagLevelThreeLabel);
      }
      default:
        return null;
    }
  };

  const generateTagsTabs = () => {
    if (!tagPanelState.currentTab) {
      return <Spin />;
    }

    const tabItems = [
      {
        label: t('tag-panel-tabs-level-one-tag'),
        key: 'tag-level-one',
        children:
          tagPanelState.currentTab === 'tag-level-one' && renderTabContent(),
      },
      {
        label: t('tag-panel-tabs-level-two-tag'),
        key: 'tag-level-two',
        children:
          tagPanelState.currentTab === 'tag-level-two' && renderTabContent(),
      },
      {
        label: t('tag-panel-tabs-level-three-tag'),
        key: 'tag-level-three',
        children:
          tagPanelState.currentTab === 'tag-level-three' && renderTabContent(),
      },
    ];

    return (
      <Tabs
        defaultActiveKey="tag-level-one"
        onChange={(activeKey) => {
          onTabChange(activeKey);
        }}
        activeKey={tagPanelState.currentTab}
        items={tabItems}
      />
    );
  };

  return (
    <Col
      pull={1}
      span={22}
      push={1}
      id="tag-panel-id"
      key={`${tagPanelState.currentTab}${uniqueId()}`}
    >
      <div>
        <Header
          title={t('tag-panel-title')}
          subtitle={t('tag-panel-subtitle')}
          showEllipsis
        >
          <Row className="TagPanel">
            <Col span={24}>{generateTagsTabs()}</Col>
          </Row>
        </Header>
      </div>
    </Col>
  );
};
