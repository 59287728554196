import { Button, Modal, Progress } from 'antd';
import React, { useEffect, useState } from 'react';

import './RepositoryViewPdfModal.scss';

interface IModalState {
  objectUrl: string;
}

export interface IRepositoryViewPdfModalProps {
  open: boolean;
  title: string;
  fileName: string;
  downloadText: string;
  closeText: string;
  cancelText: string;
  progress: number;
  loading: boolean;
  onOk: () => void;
  onCancel: () => void;
  fileData: string;
}

export const RepositoryViewPdfModal: React.FC<IRepositoryViewPdfModalProps> = ({
  open,
  title,
  fileName,
  downloadText,
  closeText,
  cancelText,
  progress,
  loading,
  onOk,
  onCancel,
  fileData,
}) => {
  const [modalState, setModalState] = useState<IModalState>({
    objectUrl: '',
  });

  useEffect(() => {
    if (!fileData || loading || !open) {
      return;
    }

    const objectUrl = window.URL.createObjectURL(
      new Blob([fileData], { type: 'application/pdf' }),
    );
    setModalState({ objectUrl: objectUrl });
  }, [fileData, loading, open]);

  return (
    <Modal
      centered
      bodyStyle={{ height: '80vh', overflowY: 'auto' }}
      width={'75vw'}
      open={open}
      title={`${title} ${fileName}`}
      confirmLoading={loading}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {loading ? cancelText : closeText}
        </Button>,
        <Button key="download" type="primary" loading={loading} onClick={onOk}>
          {downloadText}
        </Button>,
      ]}
    >
      {loading && (
        <div className="progress-container">
          <Progress type="circle" percent={progress} />
        </div>
      )}

      {!loading && (
        <div className="pdf-container">
          <iframe
            src={modalState.objectUrl}
            datatype="application/pdf"
            width="100%"
            height="100%"
            style={{ overflow: 'auto' }}
          ></iframe>
        </div>
      )}
    </Modal>
  );
};
