import React, { Fragment } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Row, Col } from 'antd';

import { Login } from '../Login/Login';
import { Navbar } from './Navbar/Navbar';
import { Footer } from './Footer/Footer';

import { RequestAccess } from '../RequestAccess/RequestAccess';
import { ConcludedRequestAccess } from '../RequestAccess/ConcludedRequestAccess/ConcludedRequestAccess';
import { ForgotPassword } from '../ForgotPassword/ForgotPassword';
import { ConcludedForgotPassword } from '../ForgotPassword/ConcludedForgotPassword/ConcludedForgotPassword';
import { Home } from '../Home/Home';
import { AdministrativePanelHeader } from '../AdministrativePanel/AdministrativePanel';
import './Container.scss';
import { CreatedPages } from '../PagesList/CreatedPages/CreatedPages';
import { PagesList } from '../PagesList/PagesList';
import { CreatePassword } from '../CreatePassword/CreatePassword';
import { About } from '../About/About';
import { ConcludedCreatePassword } from '../CreatePassword/ConcludedCreatePassword/ConcludedCreatePassword';
import { ActivateUserByEmail } from '../ActivateUserByEmail/ActivateUserByEmail';
import { RedocAPI } from '../../components/RedocAPI/RedocAPI';
import { RequestTracker } from '../RequestTracker/RequestTracker';
import { ModalToUserAcceptTerm } from '../ModalToUserAcceptTerm/ModalToUserAcceptTerm';
import { MyProfile } from '../MyProfile/MyProfile';
import { CreateReadEditPagesCreatePages } from '../PagesList/CreateReadEditPages/CreateReadEditPages';
import { Repository } from '../Repository/Repository';
import { TagsPanel } from '../TagsPanel/TagsPanel';

export const Container = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <RequestTracker />
        <ModalToUserAcceptTerm />
        <Navbar />
        <Row id="overflow-row" className="overflow-row">
          <Col span={24}>
            <Row id="content-row" className="content-row">
              <Routes>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/login" element={<Login />} />
                <Route path="/repositories" element={<Repository />} />
                <Route path="/repositories/:releaseId" element={<Repository />} />
                <Route path="/repositories/:releaseId/download/:fileId" element={<Repository />} />
                <Route path="/request-access" element={<RequestAccess />} />
                <Route
                  path="/request-access/concluded"
                  element={<ConcludedRequestAccess />}
                />
                <Route
                  path="/activate-account/*"
                  element={<ActivateUserByEmail />}
                />
                <Route path="/home" element={<Home />} />
                <Route path="/forgot-password/" element={<ForgotPassword />} />
                <Route
                  path="/forgot-password/concluded"
                  element={<ConcludedForgotPassword />}
                />
                <Route path="/changePassword/*" element={<CreatePassword />} />
                <Route
                  path="/create-password/concluded"
                  element={<ConcludedCreatePassword />}
                />
                <Route
                  path="/administrative-panel/*"
                  element={<AdministrativePanelHeader />}
                />
                <Route
                  path="/tags-panel/*"
                  element={<TagsPanel />}
                />
                <Route path="/created-pages" element={<PagesList />} />
                <Route path="/edit-pages" element={<CreatedPages />} /> 
                <Route path="/pages" element={<CreateReadEditPagesCreatePages />} />
                <Route path="/pages/:slug" element={ <CreateReadEditPagesCreatePages /> } />
                <Route path="/about" element={<About />} />
                <Route path="/my-profile" element={<MyProfile />} />
                <Route path="*" element={<Navigate to="/home" replace />} />
              </Routes>
            </Row>
            <Footer />
          </Col>
        </Row>
      </BrowserRouter>
    </Fragment>
  );
};
