import {
  securedBaseApi,
  nonSecuredBaseApi,
  securedMultipartApi,
} from './api.config';
import { IClassification } from './classification.api';

export interface IPageRequest {
  name: string;
  description: string;
  contents: IPageContent[];
}

interface IPageContent {
  language: string;
  content: string;
}

export interface IPageResponse {
  id: number;
  modifiedBy: string;
  name: string;
  description: string;
  contents: IPageResponseContent[];
  classifications: IClassification[];
  createdAt: string;
  updatedAt: string;
  numberOfElements: number;
  optionsVisibility: boolean;
}

interface IPageResponseContent {
  id: number;
  language: string;
  content: string;
  createdAt: string;
  updatedAt: string;

  summary: any;
}

export interface IPageList {
  content: IPageResponse[];
  numberOfElements: number;
  totalElements: number;
}

export const pageApi = {
  sendPage: (pageBody: IPageRequest) => {
    return securedBaseApi.post<IPageResponse>('/pages', pageBody);
  },

  // getPageById: (id: number) => {
  //   return nonSecuredBaseApi.get<IPageResponse>(
  //     `/pages/${id}`
  //   )
  // },

  getPageToUpdate: (id: number) => {
    return securedBaseApi.get<IPageResponse>(`/pages/${id}/to-update`);
  },

  updatePage: (id: number, pageBody: IPageRequest) => {
    return securedBaseApi.put<IPageResponse>(`/pages/${id}`, pageBody);
  },

  getAllPages: () => {
    return nonSecuredBaseApi.get<IPageList>(`/pages?size=500`);
  },

  getAllPagesWhileFiltering: (queryString: string) => {
    return securedBaseApi.get<IPageList>(`/pages?${queryString}`);
  },

  findPageByTitle: (title: string) => {
    return nonSecuredBaseApi.get<IPageResponse>(`/pages?name=${title}`);
  },

  // findPageByCreator: (creator: string) => {
  //   return securedBaseApi.get<IPageResponse>(`/pages?modifiedBy${creator}`);
  // },

  someoneIsUpdating: (id: number, isUpdating: boolean) => {
    return securedBaseApi.patch(
      `/pages/${id}/update-information?isUpdating=${isUpdating}`,
    );
  },

  uploadImageInPage: (image: FormData) => {
    return securedMultipartApi.post('/documentations/upload/image', image);
  },

  // deleteOneDocumentation: (id: number) => {
  //   return securedBaseApi.delete(`/pages/${id}`)
  // },

  // deleteManypages: (id: number[]) => {
  //   return securedBaseApi.post(`/pages/batch/delete`, id)
  // },
};
