import { Col, Row, Spin, Tabs } from 'antd';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import uniqid from 'uniqid';

import queryString from 'query-string';
import { Header } from '../../components/Header/Header';
import { RootState, store } from '../../store/store';
import { AdministrativePanelSlice } from './AdministrativePanel.Slice';
import { CompanyListing } from './Companies/CompanyListing/CompanyListing';
import { NewCompany } from './Companies/NewCompany/NewCompany';
import { NewUser } from './Users/NewUser/NewUser';
import { UsersListing } from './Users/UsersListing/UsersListing';
import { NewsListing } from './News/NewsListing/NewsListing';
import { CreateNews } from './News/NewNews/NewNews';
import {
  checkChangesInCompanyRegisterOrEdit,
  checkChangesInUserRegisterOrEdit,
  checkChangesInNewsRegisterOrEdit,
} from '../../helpers/newRegisterChangeChecker/newRegisterChangeChecker';
import { checkChangesOnCreateOrEditTerms } from '../../helpers/termsChangeChecker/termsChangeChecker';
import { showModal } from '../../helpers/showModal/showModal';
import { Requests } from './Requests/Requests';
import { PendingRequestsBadge } from '../../components/PendingRequestsBadge/PendingRequestsBadge';

import './AdministrativePanel.scss';
import { NewTerms } from './Terms/NewTerms/NewTerms';
import { TermsListing } from './Terms/TermsListing/TermsListing';
import { LogsListing } from './Logs/LogsListing/LogsListing';
import { PermissionsListing } from './Permissions/PermissionsListing/PermissionsListing';
import { PopUp } from './PopUp/NewPopUp/PopUp';
import { PopUpListing } from './PopUp/PopUpListing/PopUpListing';
import { MailingList } from './MailingList/MailingList/MailingList';

export const AdministrativePanelHeader: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const administrativePanelState = useSelector(
    (state: RootState) => state.administrativePanel,
  );

  const tabKeysList = [
    'users',
    'companies',
    'news',
    'requests',
    'permissions',
    'terms',
    'logs',
    'pop-up',
    'mailing-list',
  ];

  const ADMINISTRATIVE_PANEL_ROUTE = 'administrative-panel';
  const parsedUrl = queryString.parse(window.location.search);

  useEffect(() => {
    const tab = tabKeysList.includes(parsedUrl.tab as string)
      ? parsedUrl.tab
      : 'users';
    dispatch(AdministrativePanelSlice.actions.TAB_CHANGE(tab));
  }, [dispatch, parsedUrl.tab, tabKeysList]);

  const { actions } = AdministrativePanelSlice;

  useEffect(() => {
    return () => {
      dispatch(actions.BACK_TO_ORIGINAL_STATE());
    };
  }, [dispatch, actions]);

  const tabChangeVerification = (
    activeKey: string,
    verifyChangesFunction: Function,
  ) => {
    const changes = verifyChangesFunction(
      window.location.href.includes('id=') ? 'edit' : 'create',
    );

    if (changes) {
      return showModal(
        'warning',
        t('administrative-panel-discard-changes-title'),
        t('administrative-panel-discard-changes-content'),
        () => {
          navigate(`/${ADMINISTRATIVE_PANEL_ROUTE}?tab=${activeKey}`);
          dispatch(actions.TAB_CHANGE(activeKey));
        },
      );
    }

    navigate(`/${ADMINISTRATIVE_PANEL_ROUTE}?tab=${activeKey}`);
    dispatch(actions.TAB_CHANGE(activeKey));
  };

  const onTabChange = (activeKey: string) => {
    if (
      window.location.href.includes('tab=users') &&
      window.location.href.includes('add_or_edit')
    ) {
      return tabChangeVerification(activeKey, checkChangesInUserRegisterOrEdit);
    }

    if (
      window.location.href.includes('tab=companies') &&
      window.location.href.includes('add_or_edit')
    ) {
      return tabChangeVerification(
        activeKey,
        checkChangesInCompanyRegisterOrEdit,
      );
    }

    if (
      window.location.href.includes('tab=news') &&
      window.location.href.includes('add_or_edit')
    ) {
      return tabChangeVerification(activeKey, checkChangesInNewsRegisterOrEdit);
    }

    if (
      window.location.href.includes('tab=terms') &&
      window.location.href.includes('add_or_edit')
    ) {
      return tabChangeVerification(activeKey, checkChangesOnCreateOrEditTerms);
    }

    if (window.location.href.includes('tab=pop-up')) {
      return tabChangeVerification(
        activeKey,
        () => store.getState().popUp.hasChanged,
      );
    }

    if (
      store.getState().permissionsByCompanies.listing.showCancelOrSaveChanges ||
      store.getState().permissionsByRepositories.listing.showCancelOrSaveChanges
    ) {
      return tabChangeVerification(activeKey, () => true);
    }

    navigate(`/${ADMINISTRATIVE_PANEL_ROUTE}?tab=${activeKey}`);
    dispatch(actions.TAB_CHANGE(activeKey));
  };

  const renderTabContent = () => {
    const isEditMode = location.pathname.includes('add_or_edit');
    switch (administrativePanelState.currentTab) {
      case 'users':
        return isEditMode ? <NewUser /> : <UsersListing />;
      case 'companies':
        return isEditMode ? <NewCompany /> : <CompanyListing />;
      case 'news':
        return isEditMode ? <CreateNews /> : <NewsListing />;
      case 'terms':
        return isEditMode ? <NewTerms /> : <TermsListing />;
      case 'permissions':
        return <PermissionsListing />;
      case 'logs':
        return <LogsListing />;
      case 'requests':
        return <Requests />;
      case 'pop-up':
        return isEditMode ? <PopUp /> : <PopUpListing />;
      case 'mailing-list':
        return <MailingList />;
      default:
        return null;
    }
  };

  const generateTabsAfterDefinedInSlice = () => {
    if (!administrativePanelState.currentTab) {
      return <Spin />;
    }

    const tabItems = [
      {
        label: t('administrative-panel-tabs-users'),
        key: 'users',
        children:
          administrativePanelState.currentTab === 'users' && renderTabContent(),
      },
      {
        label: t('administrative-panel-tabs-companies'),
        key: 'companies',
        children:
          administrativePanelState.currentTab === 'companies' &&
          renderTabContent(),
      },
      {
        label: t('administrative-panel-tabs-news'),
        key: 'news',
        children:
          administrativePanelState.currentTab === 'news' && renderTabContent(),
      },
      {
        label: t('administrative-panel-tabs-terms'),
        key: 'terms',
        children:
          administrativePanelState.currentTab === 'terms' && renderTabContent(),
      },
      {
        label: t('administrative-panel-tabs-permissions'),
        key: 'permissions',
        children:
          administrativePanelState.currentTab === 'permissions' &&
          renderTabContent(),
      },
      {
        label: t('administrative-panel-tabs-logs'),
        key: 'logs',
        children:
          administrativePanelState.currentTab === 'logs' && renderTabContent(),
      },
      {
        label: (
          <Fragment>
            {t('administrative-panel-tabs-requests')}
            <PendingRequestsBadge marginLeft />
          </Fragment>
        ),
        key: 'requests',
        children:
          administrativePanelState.currentTab === 'requests' &&
          renderTabContent(),
      },
      {
        label: t('administrative-panel-tabs-pop-up'),
        key: 'pop-up',
        children:
          administrativePanelState.currentTab === 'pop-up' &&
          renderTabContent(),
      },
      {
        label: t('administrative-panel-tabs-mailing-list'),
        key: 'mailing-list',
        children:
          administrativePanelState.currentTab === 'mailing-list' &&
          renderTabContent(),
      },
    ];

    return (
      <Tabs
        onChange={(activeKey) => {
          onTabChange(activeKey);
        }}
        activeKey={administrativePanelState.currentTab}
        items={tabItems}
      />
    );
  };

  return (
    <Col
      pull={1}
      span={22}
      push={1}
      id="administrative-panel-id"
      key={`${administrativePanelState.currentTab}${uniqid()}`}
    >
      <div>
        <Header title={t('administrative-panel-title')} showEllipsis>
          <Row className="AdministrativePanel">
            <Col span={24}>{generateTabsAfterDefinedInSlice()}</Col>
          </Row>
        </Header>
      </div>
    </Col>
  );
};
