import { Button, Form, Input, Modal, Cascader, Select } from 'antd';
import { t } from 'i18next';
import React, { useState } from 'react';

export interface CreateModalTitle {
  name: string;
  tagLevelOne?: string;
  tagLevelOneAndTwo?: string;
}

export interface IDataParentTagOptions {
  id: any;
  value: string;
  label: string;
  children?: IDataParentTagOptions;
}

export interface ITagsCreateModalProps {
  isOpen: boolean;
  setIsModalOpen: Function;
  title: string;
  okText: string;
  cancelText: string;
  requiredText: string;
  onCreateTag: (value: CreateModalTitle, selectIds: any) => void;
  dataLevelOneTagOptions: IDataParentTagOptions[] | undefined;
  dataParentTagOptions: IDataParentTagOptions[] | undefined;
  handleCancel: () => void;
  isLevelTwoTag: boolean;
  isLevelThreeTag: boolean;
}

export const TagCreateModal: React.FC<ITagsCreateModalProps> = ({
  isOpen,
  setIsModalOpen,
  title,
  okText,
  cancelText,
  requiredText,
  onCreateTag,
  handleCancel,
  dataLevelOneTagOptions,
  dataParentTagOptions,
  isLevelTwoTag,
  isLevelThreeTag,
}) => {
  const [levelOneSelectedId, setLevelOneSelectedId] = useState<number | null>(
    null,
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [form] = Form.useForm();
  const handleInputChange = () => {
    const nameValue = form.getFieldValue('name') || '';
    const tagLevelOneSelect = form.getFieldValue('tagLevelOne');
    const tagLevelOneAndTwoSelect = form.getFieldValue('tagLevelOneAndTwo');

    const isNameValid = nameValue.length >= 2 && nameValue.length <= 30;
    const isLevelOneTagValid = !isLevelTwoTag || tagLevelOneSelect;
    const isLevelOneAndTwoTagValid =
      !isLevelThreeTag || tagLevelOneAndTwoSelect;

    setIsSaveDisabled(
      !(isNameValid && isLevelOneTagValid && isLevelOneAndTwoTagValid),
    );
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      if (isLevelTwoTag) {
        onCreateTag(values, levelOneSelectedId);
        form.resetFields();
        setIsModalOpen(false);
        setIsModalOpen(false);
      } else if (isLevelThreeTag) {
        const selectedLevelOneAndTwoTagId = form.getFieldValue('selectedIds');
        const levelTwoId = selectedLevelOneAndTwoTagId[1];
        onCreateTag(values, levelTwoId);
        form.resetFields();
        setIsModalOpen(false);
      } else {
        onCreateTag(values, null);
        form.resetFields();
        setIsModalOpen(false);
      }
    });
  };

  return (
    <>
      <Modal
        title={title}
        visible={isOpen}
        centered
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {cancelText}
          </Button>,
          <Button
            key="save"
            type="primary"
            disabled={isSaveDisabled}
            onClick={() => form.submit()}
          >
            {okText}
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="tag-create-form"
          onFinish={onFinish}
          onFieldsChange={handleInputChange}
        >
          {isLevelTwoTag && (
            <>
              <p>{t('tag-panel-new-tag-select-level-one-tag')}</p>
              <Form.Item
                name="tagLevelOne"
                label={t('tag-panel-level-one-tag-label')}
                rules={[{ required: true, message: requiredText }]}
              >
                <Select
                  id="main-tag-name"
                  onChange={(_value, option) => {
                    const selectedOption = option as IDataParentTagOptions;
                    setLevelOneSelectedId(selectedOption.id);
                  }}
                  options={dataLevelOneTagOptions}
                />
              </Form.Item>
            </>
          )}
          {isLevelThreeTag && (
            <Form.Item
              name="tagLevelOneAndTwo"
              label={t('tag-panel-level-one-and-two-tag-label')}
              rules={[{ required: true, message: requiredText }]}
            >
              <Cascader
                options={dataParentTagOptions}
                allowClear
                onChange={(_value, selectedOptions) => {
                  form.setFieldsValue({
                    selectedIds: selectedOptions.map((option) => option.id),
                  });
                }}
              />
            </Form.Item>
          )}
          <Form.Item
            name="name"
            label={t('tag-panel-new-tag-label')}
            rules={[{ required: true, message: requiredText }]}
          >
            <Input id="tag-name" onChange={handleInputChange} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
