import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  createReadEditPageApi,
  IPageBackendDTO,
  IListPagesBackendDTO,
  IListPagesBackendResponse,
} from '../../../services/createReadEditPage.api';
import {
  IClassification,
  classificationApi,
} from '../../../services/classification.api';

const mapTreeData = (
  classificationNodes: IClassification[],
): ITagTreeNode[] => {
  return classificationNodes?.map((node) => {
    return {
      title: node.name ? node.name : '',
      value: node.id.toString(),
      children: node.subClassifications
        ? mapTreeData(node.subClassifications)
        : undefined,
    };
  });
};

export interface ICreateReadEditPageSlice {
  pageMode: 'read' | 'create' | 'edit' | undefined;
  pageSlug: string;
  pages: IPages;
  pageLoaded: boolean;
  loadedToUpdate: boolean;
  idFromLoadedPageToUpdate: number | undefined;
  pageObjectResponse: IPageBackendDTO | undefined;
  pageResponseLoading: boolean;
  pageRejected: boolean;
  pageListResponse: IListPagesBackendDTO | undefined;
  resultListResponseFilter: any;
  searchPageTitle: string;
  showAllPages: boolean;
  loadingSearchedPage: boolean;
  pageObjectResponseSearch: IListPagesBackendResponse | undefined;
  editorOutputOnEditing: string | undefined;
  editorOutputInEnglish: string | undefined;
  editorOutputInPortuguese: string | undefined;
  editorOutputInSpanish: string | undefined;
  loadedPages: boolean;
  tags: {
    classifications: IClassification[];
    loadedPageTags: boolean;
    treeData: ITagTreeNode[] | undefined;
    isLoadingTreeData: boolean;
    tags: ITag[];
    shouldUpdateTags: boolean;
    selectedTreeNodes: string[];
    openTagModal: boolean;
  };
}

export interface ITagTreeNode {
  title: string;
  value: string;
  children?: ITagTreeNode[];
}

export interface ITagValueMap {
  parent: ITagValueMap | null;
  title: string;
  level: number;
  value: string;
  children: ITagValueMap[];
}

export interface ITag {
  title: string;
  value: string;
  level: number;
}

interface IPageAndLanguage {
  language: 'PORTUGUESE' | 'ENGLISH' | 'SPANISH';
  title: string;
  description: string;
  contents: string;
}

interface IPages {
  languageDropdown: 'PORTUGUESE' | 'ENGLISH' | 'SPANISH' | undefined;
  finishedCreatingPage: boolean;
  idFromRecentlyCreatedPage: number | undefined;
  isValidToCreateOrUpdatePageInPortuguese: boolean;
  isValidToCreateOrUpdatePageInEnglish: boolean;
  isValidToCreateOrUpdatePageInSpanish: boolean;
  startPageValidation: boolean;
  pageTitleInitialValue: string;
  pageDescriptionInitialValue: string;
  pageEditorInitialValue: string;
  pageInPortuguese: IPageAndLanguage;
  pageInEnglish: IPageAndLanguage;
  pageInSpanish: IPageAndLanguage;
}

const initialState: ICreateReadEditPageSlice = {
  pageMode: undefined,
  pageSlug: '',
  pages: {
    languageDropdown: undefined,
    finishedCreatingPage: false,
    idFromRecentlyCreatedPage: undefined,
    isValidToCreateOrUpdatePageInPortuguese: true,
    isValidToCreateOrUpdatePageInEnglish: true,
    isValidToCreateOrUpdatePageInSpanish: true,
    startPageValidation: false,
    pageTitleInitialValue: '',
    pageDescriptionInitialValue: '',
    pageEditorInitialValue: '',
    pageInPortuguese: {
      language: 'PORTUGUESE',
      title: '',
      description: '',
      contents: '',
    },
    pageInEnglish: {
      language: 'ENGLISH',
      title: '',
      description: '',
      contents: '',
    },
    pageInSpanish: {
      language: 'SPANISH',
      title: '',
      description: '',
      contents: '',
    },
  },
  pageLoaded: false,
  loadedToUpdate: false,
  idFromLoadedPageToUpdate: undefined,
  pageObjectResponse: undefined,
  pageResponseLoading: false,
  pageRejected: false,
  pageListResponse: undefined,
  resultListResponseFilter: undefined,
  pageObjectResponseSearch: undefined,
  loadingSearchedPage: false,
  searchPageTitle: '',
  showAllPages: true,
  editorOutputOnEditing: undefined,
  editorOutputInEnglish: undefined,
  editorOutputInPortuguese: undefined,
  editorOutputInSpanish: undefined,
  loadedPages: false,
  tags: {
    classifications: [],
    loadedPageTags: false,
    isLoadingTreeData: false,
    treeData: undefined,
    tags: [],
    shouldUpdateTags: false,
    selectedTreeNodes: [],
    openTagModal: false,
  },
};

export const asyncPageActions = {
  GET_PAGE_BY_SLUG: createAsyncThunk(
    'GET_PAGE_BY_SLUG',
    async (payload: any) => {
      const pageResponse = await createReadEditPageApi.getPageBySlug(
        payload.slug,
      );

      return {
        pageObjectResponse: pageResponse.data,
      };
    },
  ),
  GET_ALL_PAGES: createAsyncThunk('GET_ALL_PAGES', async (language: string) => {
    const pageListResponse = await createReadEditPageApi.getAllPages(language);

    return {
      pageListResponse: pageListResponse.data,
    };
  }),
  FIND_PAGE_BY_TITLE: createAsyncThunk(
    'FIND_PAGE_BY_TITLE',
    async (arg: any) => {
      const pageResponse = await createReadEditPageApi.findPageByTitle(
        arg.language,
        arg.title,
      );

      return {
        title: arg.title,
        pageObjectResponse: pageResponse.data,
      };
    },
  ),
  CREATE_A_NEW_PAGE: createAsyncThunk(
    'CREATE_A_NEW_PAGE',
    async (payload: any) => {
      const newPageResponse =
        await createReadEditPageApi.createNewPage(payload);

      return {
        createdPage: newPageResponse.data,
      };
    },
  ),
  GET_PAGE_TO_UPDATE: createAsyncThunk(
    'GET_PAGE_TO_UPDATE',
    async (payload: any) => {
      const updatedPageResponse =
        await createReadEditPageApi.getPageToUpdate(payload);

      return {
        updatedDocumentation: updatedPageResponse.data,
      };
    },
  ),
  KEEP_ALIVE: createAsyncThunk('KEEP_ALIVE', async (arg: any) => {
    await createReadEditPageApi.keepAlive(arg.id, arg.isUpdating);
  }),
  UPDATE_A_PAGE: createAsyncThunk('UPDATE_A_PAGE', async (arg: any) => {
    await createReadEditPageApi.updatePage(arg.id, arg.payload);
  }),
  GET_ALL_CLASSIFICATIONS: createAsyncThunk(
    'GET_ALL_CLASSIFICATIONS',
    async () => {
      const getAllClassificationsResponse =
        await classificationApi.getAllClassifications();

      return {
        classifications: getAllClassificationsResponse.data,
      };
    },
  ),
  GET_PAGE_BY_CLASSIFICATIONS_ID: createAsyncThunk(
    'GET_PAGE_BY_CLASSIFICATIONS_ID',
    async (payload: any) => {
      const pageResponse =
        await createReadEditPageApi.getPageByClassificationsId(payload);
      return {
        pageListResponse: pageResponse.data,
        resultListResponseFilter: pageResponse.data,
      };
    },
  ),
};

export const CreateReadEditPageSlice = createSlice({
  name: 'CreateReadEditPageSlice',
  initialState,
  reducers: {
    PAGE_SET_PAGE_MODE: (state, action) => {
      state.pageMode = action.payload;
    },
    PAGE_SET_SLUG: (state, action) => {
      state.pageSlug = action.payload;
    },
    BACK_TO_ORIGINAL_STATE: () => {
      return initialState;
    },
    BACK_TO_ORIGINAL_STATE_PERSISTING_ID_FROM_UPDATED_PAGE: (state) => {
      return {
        ...initialState,
        idFromLoadedPageToUpdate: state.idFromLoadedPageToUpdate,
      };
    },
    SET_PORTUGUESE_CONTENT: (state, action) => {
      state.pages.pageInPortuguese.contents = action.payload;
    },
    SET_ENGLISH_CONTENT: (state, action) => {
      state.pages.pageInEnglish.contents = action.payload;
    },
    SET_SPANISH_CONTENT: (state, action) => {
      state.pages.pageInSpanish.contents = action.payload;
    },
    SET_DROPDOWN_LANGUAGE: (state, action) => {
      state.pages.languageDropdown = action.payload;

      assignLanguageFromPropertiesToInitialValueProperties(
        state,
        action.payload,
      );
    },
    SAVE_PAGE_CONTENT: (state, action) => {
      if (state.editorOutputOnEditing !== undefined) {
        switch (state.pages.languageDropdown) {
          case 'PORTUGUESE':
            state.editorOutputInPortuguese = state.editorOutputOnEditing;
            state.pages.pageInPortuguese.contents =
              state.editorOutputInPortuguese;

            break;

          case 'ENGLISH':
            state.editorOutputInEnglish = state.editorOutputOnEditing;
            state.pages.pageInEnglish.contents = state.editorOutputInEnglish;

            break;
          case 'SPANISH':
            state.editorOutputInSpanish = state.editorOutputOnEditing;
            state.pages.pageInSpanish.contents = state.editorOutputInSpanish;

            break;

          default:
            break;
        }
      }
      switch (action.payload.language) {
        case 'PORTUGUESE':
          if (action.payload.pageTitle !== undefined) {
            state.pages.pageInPortuguese.title = action.payload.pageTitle;
          }

          if (action.payload.pageDescription !== undefined) {
            state.pages.pageInPortuguese.description =
              action.payload.pageDescription;
          }

          break;

        case 'ENGLISH':
          if (action.payload.pageTitle !== undefined) {
            state.pages.pageInEnglish.title = action.payload.pageTitle;
          }

          if (action.payload.pageDescription !== undefined) {
            state.pages.pageInEnglish.description =
              action.payload.pageDescription;
          }

          break;

        case 'SPANISH':
          if (action.payload.pageTitle !== undefined) {
            state.pages.pageInSpanish.title = action.payload.pageTitle;
          }

          if (action.payload.pageDescription !== undefined) {
            state.pages.pageInSpanish.description =
              action.payload.pageDescription;
          }

          break;

        default:
          break;
      }

      checkIfDocumentationIsValid(state);

      assignLanguageFromPropertiesToInitialValueProperties(
        state,
        action.payload.newLanguage,
      );

      state.pages.languageDropdown = action.payload.newLanguage;

      state.editorOutputOnEditing = initialState.editorOutputOnEditing;

      state.pageSlug = action.payload.pageSlug;
    },
    UPDATE_PAGE_STATE_AND_START_VALIDATION: (state, action) => {
      state.pageResponseLoading = true;
      state.pageSlug = action.payload.pageSlug;

      if (state.editorOutputOnEditing !== undefined) {
        let editorOutputOnEditing = state.editorOutputOnEditing;

        // Check if we are not receiving an empty line from the editor
        const regex = /<p>\s*<\/p>/;
        if (regex.test(editorOutputOnEditing)) {
          editorOutputOnEditing = '';
        }

        switch (state.pages.languageDropdown) {
          case 'PORTUGUESE':
            state.editorOutputInPortuguese = editorOutputOnEditing;
            state.pages.pageInPortuguese.contents =
              state.editorOutputInPortuguese;

            break;

          case 'ENGLISH':
            state.editorOutputInEnglish = editorOutputOnEditing;
            state.pages.pageInEnglish.contents = state.editorOutputInEnglish;

            break;
          case 'SPANISH':
            state.editorOutputInSpanish = editorOutputOnEditing;
            state.pages.pageInSpanish.contents = state.editorOutputInSpanish;

            break;

          default:
            break;
        }
      }

      switch (state.pages.languageDropdown) {
        case 'PORTUGUESE':
          if (action.payload.pageTitle !== undefined) {
            state.pages.pageInPortuguese.title = action.payload.pageTitle;
          }
          if (action.payload.pageDescription !== undefined) {
            state.pages.pageInPortuguese.description =
              action.payload.pageDescription;
          }

          break;

        case 'ENGLISH':
          if (action.payload.pageTitle !== undefined) {
            state.pages.pageInEnglish.title = action.payload.pageTitle;
          }
          if (action.payload.pageDescription !== undefined) {
            state.pages.pageInEnglish.description =
              action.payload.pageDescription;
          }

          break;

        case 'SPANISH':
          if (action.payload.pageTitle !== undefined) {
            state.pages.pageInSpanish.title = action.payload.pageTitle;
          }
          if (action.payload.pageDescription !== undefined) {
            state.pages.pageInSpanish.description =
              action.payload.pageDescription;
          }

          break;

        default:
          break;
      }
      checkIfDocumentationIsValid(state);

      state.pages.startPageValidation = true;
    },
    STOP_PAGE_VALIDATING: (state) => {
      state.pages.startPageValidation = false;
      state.pageResponseLoading = false;

      assignLanguageFromPropertiesToInitialValueProperties(
        state,
        state.pages.languageDropdown as string,
      );
    },
    FINISH_CREATING_PAGE: (state) => {
      state.pageObjectResponse = initialState.pageObjectResponse;
      state.pages = initialState.pages;
      state.pageResponseLoading = false;
      state.pageMode = initialState.pageMode;
    },
    BACK_TO_ALL_PAGES_LISTING: (state) => {
      state.showAllPages = true;
    },
    EDITOR_ON_CHANGE: (state, action) => {
      state.editorOutputOnEditing = action.payload;
    },
    SET_TAGS_SELECTED_TREE_NODES: (state, action) => {
      state.tags.selectedTreeNodes = action.payload;
    },
    SET_TAGS: (state, action) => {
      state.tags.tags = action.payload;
    },
    SET_SHOULD_UPDATE_TAGS: (state, action) => {
      state.tags.shouldUpdateTags = action.payload;
    },
    OPEN_TAG_MODAL: (state) => {
      state.tags.openTagModal = true;
    },
    CLOSE_TAG_MODAL: (state) => {
      state.tags.openTagModal = false;
    },
    SET_LOADED_PAGE_TAGS: (state, action) => {
      state.tags.loadedPageTags = action.payload;
    },
    MAP_CLASSIFICATIONS_TAGS: (state) => {
      const mapData = (
        classificationNodes: IClassification[],
        pageClassifications: IClassification[] | undefined,
      ): ITagTreeNode[] => {
        return classificationNodes
          ?.filter((node) => {
            return pageClassifications?.some((elem) => {
              return elem.id === node.id;
            })
              ? true
              : node.enabled;
          })
          .map((node) => {
            return {
              title: node.name ? node.name : '',
              value: node.id.toString(),
              children: node.subClassifications
                ? mapData(node.subClassifications, pageClassifications)
                : undefined,
            };
          });
      };

      state.tags.treeData = mapData(
        state.tags.classifications,
        state.pageObjectResponse?.classifications,
      );
    },
    CLEAR_TREE_FILTER: (state) => {
      state.resultListResponseFilter = undefined;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncPageActions.GET_PAGE_BY_SLUG.pending, (state) => {
      state.pageResponseLoading = true;
      state.pageLoaded = false;
    }),
    builder.addCase(
      asyncPageActions.GET_PAGE_BY_SLUG.fulfilled,
      (state, action) => {
        state.pageObjectResponse = action.payload.pageObjectResponse;
        state.pageResponseLoading = false;
        state.pageLoaded = true;

        formatDocumentationProperties(state);
      },
    ),
    builder.addCase(asyncPageActions.GET_PAGE_BY_SLUG.rejected, (state) => {
      state.pageRejected = true;
      state.pageResponseLoading = false;
      state.pageLoaded = false;
    }),
    builder.addCase(
      asyncPageActions.GET_ALL_PAGES.fulfilled,
      (state, action) => {
        state.pageListResponse = action.payload.pageListResponse;

        state.loadedPages = true;
      },
    ),
    builder.addCase(
      asyncPageActions.FIND_PAGE_BY_TITLE.fulfilled,
      (state, action) => {
        state.searchPageTitle = action.payload.title;
        state.showAllPages = false;
        state.loadingSearchedPage = false;
        state.pageObjectResponseSearch = action.payload.pageObjectResponse;
      },
    ),
    builder.addCase(asyncPageActions.GET_PAGE_TO_UPDATE.pending, (state) => {
      state.pageResponseLoading = true;
    }),
    builder.addCase(
      asyncPageActions.GET_PAGE_TO_UPDATE.fulfilled,
      (state, action) => {
        state.pageObjectResponse = action.payload.updatedDocumentation;
        state.pageResponseLoading = false;

        formatDocumentationProperties(state);

        state.loadedToUpdate = true;
        state.idFromLoadedPageToUpdate = action.payload.updatedDocumentation.id;
      },
    ),
    builder.addCase(asyncPageActions.GET_PAGE_TO_UPDATE.rejected, (state) => {
      state.pageRejected = true;
      state.pageResponseLoading = false;
    }),
    builder.addCase(asyncPageActions.CREATE_A_NEW_PAGE.pending, (state) => {
      state.pages.startPageValidation = false;
    }),
    builder.addCase(asyncPageActions.CREATE_A_NEW_PAGE.rejected, (state) => {
      state.pageResponseLoading = false;

      assignLanguageFromPropertiesToInitialValueProperties(
        state,
        state.pages.languageDropdown as string,
      );
    }),
    builder.addCase(
      asyncPageActions.CREATE_A_NEW_PAGE.fulfilled,
      (state, action) => {
        state.pages.finishedCreatingPage = true;
        state.pages.idFromRecentlyCreatedPage = action.payload.createdPage.id;

        state.editorOutputOnEditing = initialState.editorOutputOnEditing;
      },
    ),
    builder.addCase(asyncPageActions.UPDATE_A_PAGE.pending, (state) => {
      state.pages.startPageValidation = false;
    }),
    builder.addCase(asyncPageActions.UPDATE_A_PAGE.rejected, (state) => {
      state.pageResponseLoading = false;

      assignLanguageFromPropertiesToInitialValueProperties(
        state,
        state.pages.languageDropdown as string,
      );
    }),
    builder.addCase(asyncPageActions.UPDATE_A_PAGE.fulfilled, (state) => {
      state.pages.finishedCreatingPage = true;
      state.pages.idFromRecentlyCreatedPage = state.pageObjectResponse?.id;

      state.editorOutputOnEditing = initialState.editorOutputOnEditing;
    }),
    builder.addCase(
      asyncPageActions.GET_ALL_CLASSIFICATIONS.pending,
      (state) => {
        state.pageResponseLoading = true;
      },
    ),
    builder.addCase(
      asyncPageActions.GET_ALL_CLASSIFICATIONS.rejected,
      (state) => {
        state.pageResponseLoading = false;
      },
    ),
    builder.addCase(
      asyncPageActions.GET_ALL_CLASSIFICATIONS.fulfilled,
      (state, action) => {
        state.pageResponseLoading = false;
        if (action.payload.classifications) {
          state.tags.classifications = action.payload.classifications;
          state.tags.treeData = mapTreeData(state.tags.classifications);
        }
      },
    ),
    builder.addCase(
      asyncPageActions.GET_PAGE_BY_CLASSIFICATIONS_ID.pending,
      (state) => {
        state.pageResponseLoading = true;
      },
    ),
    builder.addCase(
      asyncPageActions.GET_PAGE_BY_CLASSIFICATIONS_ID.rejected,
      (state) => {
        state.pageRejected = true;
        // state.pageResponseLoading = false;
        // state.pageLoaded = false;
      },
    ),
    builder.addCase(
      asyncPageActions.GET_PAGE_BY_CLASSIFICATIONS_ID.fulfilled,
      (state, action) => {
        // state.pageListResponse = action.payload.pageListResponse;
        state.resultListResponseFilter = action.payload.pageListResponse;
        // state.loadedPages = true;
      },
    ),
  ],
});

const assignLanguageFromPropertiesToInitialValueProperties = (
  state: ICreateReadEditPageSlice,
  language: string,
) => {
  switch (language) {
    case 'PORTUGUESE':
      state.pages.pageTitleInitialValue = state.pages.pageInPortuguese.title;
      state.pages.pageDescriptionInitialValue =
        state.pages.pageInPortuguese.description;

      if (state.editorOutputInPortuguese !== undefined) {
        state.pages.pageInPortuguese.contents = state.editorOutputInPortuguese;
      }

      state.pages.pageEditorInitialValue =
        state.pages.pageInPortuguese.contents;
      break;

    case 'ENGLISH':
      state.pages.pageTitleInitialValue = state.pages.pageInEnglish.title;
      state.pages.pageDescriptionInitialValue =
        state.pages.pageInEnglish.description;

      if (state.editorOutputInEnglish !== undefined) {
        state.pages.pageInEnglish.contents = state.editorOutputInEnglish;
      }

      state.pages.pageEditorInitialValue = state.pages.pageInEnglish.contents;
      break;

    case 'SPANISH':
      state.pages.pageTitleInitialValue = state.pages.pageInSpanish.title;
      state.pages.pageDescriptionInitialValue =
        state.pages.pageInSpanish.description;

      if (state.editorOutputInSpanish !== undefined) {
        state.pages.pageInSpanish.contents = state.editorOutputInSpanish;
      }

      state.pages.pageEditorInitialValue = state.pages.pageInSpanish.contents;
      break;

    default:
      break;
  }
};

const checkIfDocumentationIsValid = (state: ICreateReadEditPageSlice) => {
  if (
    state.pages.pageInPortuguese.title &&
    state.pages.pageInPortuguese.description &&
    state.pages.pageInPortuguese.contents
  ) {
    state.pages.isValidToCreateOrUpdatePageInPortuguese = true;
  } else if (
    !state.pages.pageInPortuguese.title &&
    !state.pages.pageInPortuguese.description &&
    !state.pages.pageInPortuguese.contents
  ) {
    state.pages.isValidToCreateOrUpdatePageInPortuguese = true;
  } else state.pages.isValidToCreateOrUpdatePageInPortuguese = false;

  if (
    state.pages.pageInEnglish.title &&
    state.pages.pageInEnglish.description &&
    state.pages.pageInEnglish.contents
  ) {
    state.pages.isValidToCreateOrUpdatePageInEnglish = true;
  } else if (
    !state.pages.pageInEnglish.title &&
    !state.pages.pageInEnglish.description &&
    !state.pages.pageInEnglish.contents
  ) {
    state.pages.isValidToCreateOrUpdatePageInEnglish = true;
  } else state.pages.isValidToCreateOrUpdatePageInEnglish = false;

  if (
    state.pages.pageInSpanish.title &&
    state.pages.pageInSpanish.description &&
    state.pages.pageInSpanish.contents
  ) {
    state.pages.isValidToCreateOrUpdatePageInSpanish = true;
  } else if (
    !state.pages.pageInSpanish.title &&
    !state.pages.pageInSpanish.description &&
    !state.pages.pageInSpanish.contents
  ) {
    state.pages.isValidToCreateOrUpdatePageInSpanish = true;
  } else state.pages.isValidToCreateOrUpdatePageInSpanish = false;
};

const formatDocumentationProperties = (state: ICreateReadEditPageSlice) => {
  state.pageObjectResponse?.pageContents.forEach((el) => {
    if (el.language === 'PORTUGUESE') {
      state.pages.pageInPortuguese.title = el.title;
      state.pages.pageInPortuguese.description = el.subtitle;
      state.pages.pageInPortuguese.contents = el.mainContent;
    }

    if (el.language === 'ENGLISH') {
      state.pages.pageInEnglish.title = el.title;
      state.pages.pageInEnglish.description = el.subtitle;
      state.pages.pageInEnglish.contents = el.mainContent;
    }

    if (el.language === 'SPANISH') {
      state.pages.pageInSpanish.title = el.title;
      state.pages.pageInSpanish.description = el.subtitle;
      state.pages.pageInSpanish.contents = el.mainContent;
    }
  });

  if (state.pages.languageDropdown) {
    assignLanguageFromPropertiesToInitialValueProperties(
      state,
      state.pages.languageDropdown as string,
    );

    switch (state.pages.languageDropdown) {
      case 'PORTUGUESE':
        state.editorOutputOnEditing = state.pages.pageInPortuguese.contents;
        break;

      case 'ENGLISH':
        state.editorOutputOnEditing = state.pages.pageInEnglish.contents;
        break;

      case 'SPANISH':
        state.editorOutputOnEditing = state.pages.pageInSpanish.contents;
        break;

      default:
        break;
    }
  }
};
