import { nonSecuredBaseApi, securedBaseApi } from './api.config';
import { IClassification } from './classification.api';

export interface IPageBackendDTO {
  id: number;
  slug: string;
  active: boolean;
  createdBy: string;
  createdAt: string;
  modifiedBy: string;
  modifiedAt: string;
  pageContents: IPageResponseContents[];
  classifications: IClassification[];
  artifactIds: number[];
}

export interface IPageResponseContents {
  id: number;
  title: string;
  subtitle: string;
  language: string;
  mainContent: string;
}

export interface IListPagesBackendDTO {
  content: IListPagesBackendResponse[];
  numberOfElements: number;
  totalElements: number;
  optionsVisibility: boolean;
  idsInDatabase: number[] | undefined;
}

export interface IListPagesBackendResponse {
  id: number;
  title: string;
  subtitle: string;
  mainContent: string;
  language: string;
  description: string;
  page: {
    classifications: IClassification[];
    id: number;
    slug: string;
    active: boolean;
    createdBy: string;
    createdAt: string;
    modifiedBy: string;
    modifiedAt: string;
  };
  numberOfElements: number;
  optionsVisibility: boolean;
  createdAt: string;
  modifiedAt: string;
}

export const createReadEditPageApi = {
  getPageBySlug: (slug: string) => {
    return nonSecuredBaseApi.get<IPageBackendDTO>(`/pages/slug/${slug}`);
  },
  getPageByClassificationsId: (queryString: string) => {
    return securedBaseApi.get(`/pages/classifications?${queryString}`);
  },
  getAllPages: (language: string) => {
    return nonSecuredBaseApi.get<IListPagesBackendDTO>(
      `/pages/language/${language}?size=500`,
    );
  },
  getAllPagesWhileFiltering: (language: string, queryString: string) => {
    return securedBaseApi.get<IListPagesBackendDTO>(
      `/pages/language/${language}?${queryString}`,
    );
  },
  findPageByTitle: (language: string, title: string) => {
    return nonSecuredBaseApi.get<IListPagesBackendResponse>(
      `/pages/language/${language}?title=${title}&size=500`,
    );
  },
  createNewPage: (newPageBody: IPageBackendDTO) => {
    return securedBaseApi.post<IPageBackendDTO>('/pages', newPageBody);
  },
  getPageToUpdate: (id: number) => {
    return securedBaseApi.get<IPageBackendDTO>(`/pages/${id}/to-update`);
  },
  keepAlive: (id: number, isUpdating: boolean) => {
    return securedBaseApi.patch(
      `/pages/${id}/update-information?isUpdating=${isUpdating}`,
    );
  },
  updatePage: (id: number, pageBody: IPageBackendDTO) => {
    return securedBaseApi.put<IPageBackendDTO>(`/pages/${id}`, pageBody);
  },
  deleteOnePage: (id: number | number[]) => {
    return securedBaseApi.delete(`/pages/${id}`);
  },
  deleteManyPages: (id: number | number[]) => {
    return securedBaseApi.post(`/pages/batch/delete`, id);
  },
};
