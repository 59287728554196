import { securedBaseApi } from './api.config';
import { IPageBackendDTO } from './createReadEditPage.api';

export interface IArtifactResponse {
  id: number;
  name: string;
  description: string;
  updatedAt: string;
  genericType: string;
  totalOfContent: number;
  permissions: string[];
}

export interface IArtifactList {
  content: IArtifactResponse[];
  numberOfElements: number;
  totalElements: number;
  sortBy: string;
}

export interface IBreadcrumb {
  id: number;
  name: string;
}

export interface IContent {
  id: number;
  name: string;
  description?: string;
  type: string;
  genericType: string;
  updatedAt: string;
  contentLengthString: string;
  parentArtifactId: number;
  rootArtifactId: number;
  totalOfContent: number;
  permissions: string[];
  mark_file?: number;
}

export interface IContentList {
  content: IContent[];
  numberOfElements: number;
  totalElements: number;
  sortBy: string;
}

export interface IArtifactData {
  id?: number;
  name: string;
  description: string;
  updatedAt: string;
  createdAt: string;
  genericType: string;
  totalOfContent: number;
  permissions: string[];
  createdByFullName: string;
  pages: any;
}

export interface IContentResponse {
  artifact_data: IArtifactData;
  mark_file: number;
  permissions: string[];
  listing: IContentList;
}

export interface IRootIdResponse {
  rootId: number;
}

export interface INewArtifactBody {
  name: string;
  description: string;
  publicAccess: boolean;
}

export interface INewReleaseBody {
  name: string;
  description: string;
}

export const repositoryApi = {
  getAllArtifacts: (queryString: string) => {
    return securedBaseApi.get<IArtifactList>(
      `/artifacts?size=100000&page=0&sort=${queryString}`,
    );
  },

  findRepositoryByName: (
    rootId: number,
    parentId: number,
    searchAttribute: string,
  ) => {
    if (!rootId) {
      return securedBaseApi.get<IContentResponse>(
        `artifacts?name=${searchAttribute}&size=100000&sort=name`,
      );
    }

    return securedBaseApi.get<IContentResponse>(
      `artifacts/root/${rootId}/parent/${parentId}/search?name=${searchAttribute}&size=100000&sort=name`,
    );
  },

  getBreadcrumbById: (id: number | string) => {
    return securedBaseApi.get<IBreadcrumb[]>(`/data/breadcrumb/${id}`);
  },

  getContentById: (rootId: number, parentId: number, queryString: string) => {
    return securedBaseApi.get<IContentResponse>(
      `/artifacts/root/${rootId}/parent/${parentId}/content?size=100000&page=0&sort=${queryString}`,
    );
  },

  getRootIdByArtifactId: (id: number) => {
    return securedBaseApi.get<IRootIdResponse>(
      `/artifacts/root/id?artifactId=${id}`,
    );
  },

  newArtifact: (newArtifactBody: INewArtifactBody) => {
    return securedBaseApi.post<INewArtifactBody>('/artifacts', newArtifactBody);
  },

  newRelease: (rootId: number, id: number, newReleaseBody: INewReleaseBody) => {
    return securedBaseApi.post<INewReleaseBody>(
      `/artifacts/root/${rootId}/parent/${id}/child`,
      newReleaseBody,
    );
  },

  updateArtifactOrRelease: (
    rootId: number,
    id: number,
    body: IArtifactResponse | IContent,
  ) => {
    return securedBaseApi.put<IArtifactResponse | IContent>(
      `/artifacts/root/${rootId}/${id}`,
      body,
    );
  },

  deleteData: (rootId: number, id: number, type: string) => {
    return securedBaseApi.delete(
      `/data/root/${rootId}/content/${id}?type=${type}`,
    );
  },

  transferFile: (
    rootId: number,
    id: number,
    onTransferProgress: (progressEvent: any) => void,
    abortSignal: AbortSignal | undefined,
  ) => {
    return securedBaseApi.get(`/data/root/${rootId}/content/${id}?type=FILE`, {
      responseType: 'blob',
      onDownloadProgress: onTransferProgress,
      signal: abortSignal,
      timeout: 0,
    });
  },

  setDefaultFile: (releaseId: number, id: number) => {
    return securedBaseApi.patch(`/artifacts/${releaseId}/mark-file/${id}`);
  },

  moveFile: (destinationArtifactId: number, fileId: number) => {
    return securedBaseApi.patch(
      `/artifacts/${destinationArtifactId}/move-file/${fileId}`,
    );
  },

  moveFiles: (destinationArtifactId: number, fileIds: number[]) => {
    const ids = fileIds.join(',');
    return securedBaseApi.patch(
      `artifacts/${destinationArtifactId}/move-files/${ids}`,
    );
  },

  moveArtifact: (destinationArtifactId: number, artifactId: number) => {
    return securedBaseApi.patch(
      `/artifacts/${artifactId}/move-to/${destinationArtifactId}`,
    );
  },

  moveArtifacts: (destinationArtifactId: number, artifactIds: number[]) => {
    const ids = artifactIds.join(',');
    return securedBaseApi.patch(
      `/artifacts/${ids}/move-artifacts-to/${destinationArtifactId}`,
    );
  },
  getPageBySlug: (slug: string) => {
    return securedBaseApi.get<IPageBackendDTO>(`/pages/slug/${slug}`);
  },
};
