import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Input,
  Drawer,
  Breadcrumb,
  Dropdown,
  Space,
  MenuProps,
  Popover,
  Form,
} from 'antd';
import { showModal } from '../../helpers/showModal/showModal';
import '../../components/Header/Header.scss';
import './Repository.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  asyncActions,
  RepositoryContentTypes,
  RepositorySlice,
} from './Repository.Slice';
import {
  DownOutlined,
  FileAddFilled,
  FolderAddFilled,
  HomeOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import { RepositoryCreateEditModal } from '../../components/RepositoryCreateEditModal/RepositoryCreateEditModal';
import { IArtifactResponse, IContent } from '../../services/repository.api';
import { messageNotification } from '../../helpers/messageNotification/messageNotification';
import { RepositoryDownloadModal } from '../../components/RepositoryDownloadModal/RepositoryDownloadModal';
import { RepositoryViewTxtModal } from '../../components/RepositoryViewTxtModal/RepositoryViewTxtModal';
import { RepositoryViewPdfModal } from '../../components/RepositoryViewPdfModal/RepositoryViewPdfModal';
import { RepositoryUploadModal } from '../../components/RepositoryUploadModal/RepositoryUploadModal';
import { RepositoryTable } from './RepositoryTable/RepositoryTable';
import { debounce } from 'lodash';
import { ReactComponent as Move } from '../../assets/popover-icons/move.svg';
import { IDefaultSortBehaviour } from '../../components/PagingTable/PagingTable';
import { scrollToItemFunction } from '../../helpers/useScrollToTop/useScrollToTop';
import { Header } from '../../components/Header/Header';
import Link from 'antd/lib/typography/Link';
import { asyncPageActions } from '../PagesList/CreateReadEditPages/CreateReadEditPage.Slice';

// This conversion is necessary because the pages backend expects the full name of the language in uppercase, while the navbar stores the current language as an abbreviation.
const languageMap: { [key: string]: string } = {
  en: 'ENGLISH',
  es: 'SPANISH',
  pt: 'PORTUGUESE',
};

export const Repository: React.FC = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [searchValue, setSearchValue] = useState('');
  const [openAssociationPopover, setOpenAssociationPopover] = useState(false);
  const [associationCompleted, setAssociationCompleted] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { Search } = Input;

  const applicationLanguage = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );
  const dispatch = useDispatch<AppDispatch>();
  const repositoryState = useSelector((state: RootState) => state.repository);

  const loginState = useSelector((state: RootState) => state.login);
  const isUserAdmin = loginState.user?.role === 'A';

  const defaultFileId = useSelector(
    (state: RootState) => state.repository.defaultFileId,
  );

  const pageObjectResponse = useSelector(
    (state: RootState) => state.repository.pageObjectResponse,
  );

  const navbarState = useSelector((state: RootState) => state.navBar);

  const defaultLanguage = languageMap[navbarState.defaultLanguage];

  const { releaseId, fileId } = useParams();

  const abortControllerRef = useRef<AbortController>(new AbortController());

  useEffect(() => {
    const controller = abortControllerRef.current;
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    dispatch(RepositorySlice.actions.SET_SHOULD_UPDATE(true));
  }, [releaseId]);

  useEffect(() => {
    if (
      repositoryState.isLoading ||
      repositoryState.transferState.isTransferring
    ) {
      return;
    }

    if (!releaseId || !fileId || !repositoryState.rootId) {
      return;
    }

    const item = repositoryState.artifacts?.content.find(
      (x) => x.id === +fileId,
    );
    if (!item || item.genericType !== 'FILE') {
      return;
    }

    dispatch(RepositorySlice.actions.OPEN_DOWNLOAD_MODAL(item.name));
    transferFile(+fileId, true);

    navigate(`/repositories/${releaseId}`);
  }, [fileId, releaseId, repositoryState]);

  useEffect(() => {
    if (
      !repositoryState.downloadFileAfterTransfer ||
      repositoryState.fileDownloaded ||
      !repositoryState.transferState.isTransferComplete
    ) {
      return;
    }

    downloadTransferredFile();
    dispatch(RepositorySlice.actions.SET_FILE_DOWNLOADED(true));
  }, [repositoryState.transferState]);

  useEffect(() => {
    if (
      repositoryState.isLoading ||
      !repositoryState.shouldUpdate ||
      repositoryState.loadingSearchRepositoryByName ||
      repositoryState.searchRepositoryByName
    ) {
      return;
    }

    if (releaseId) {
      if (!repositoryState.rootId) {
        dispatch(asyncActions.GET_ROOT_ID_BY_ARTIFACT_ID(+releaseId));
      }
    } else {
      const queryString = repositoryState.repositoryFirstQueryBehaviour
        ? '&sort=name'
        : '';
      dispatch(asyncActions.GET_ALL_ARTIFACTS(queryString));
    }

    dispatch(RepositorySlice.actions.SET_SHOULD_UPDATE(false));
  }, [dispatch, repositoryState, releaseId]);

  useEffect(() => {
    if (
      !releaseId ||
      !repositoryState.rootId ||
      repositoryState.loadingSearchRepositoryByName ||
      repositoryState.searchRepositoryByName
    ) {
      return;
    }

    dispatch(asyncActions.GET_BREADCRUMB_BY_ID(releaseId));

    dispatch(
      asyncActions.GET_CONTENT_BY_ID({
        rootId: repositoryState.rootId,
        parentId: releaseId,
        queryString: 'name',
      }),
    );
  }, [releaseId, repositoryState.rootId, repositoryState.shouldUpdate]);

  const data = repositoryState.artifacts?.content;

  useEffect(() => {
    // Don't open default files if we are seaching by name
    if (!defaultFileId || repositoryState.searchRepositoryByName.length) {
      return;
    }

    const defaultFileItem = data?.find(
      (item) => item.id === defaultFileId && item.genericType === 'FILE',
    );

    if (defaultFileItem) {
      return onOpenFile(defaultFileItem);
    }
  }, [defaultFileId, data]);

  useEffect(() => {
    if (
      !repositoryState.isMovingFilesAndArtifacts ||
      !repositoryState.isMovingArtifactsComplete ||
      !repositoryState.isMovingFilesComplete
    ) {
      return;
    }

    dispatch(RepositorySlice.actions.FINISH_MOVING_FILES_AND_ARTIFACTS());
  }, [
    repositoryState.isMovingFilesAndArtifacts,
    repositoryState.isMovingArtifactsComplete,
    repositoryState.isMovingFilesComplete,
  ]);

  // Handle navigator back button click
  useEffect(() => {
    const handlePopState = () => {
      if (!repositoryState.isAtRoot && repositoryState.breadcrumb) {
        const lastOpen =
          repositoryState.breadcrumb[repositoryState.breadcrumb?.length - 1];
        dispatch(
          RepositorySlice.actions.SET_CLICKED_ITEM_ID(
            `ARTIFACT-${lastOpen.id}`,
          ),
        );
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [repositoryState.breadcrumb]);

  useEffect(() => {
    if (repositoryState.isAtRoot && repositoryState.openedRootItemId) {
      scrollToItemFunction(repositoryState.openedRootItemId);
      return;
    }

    if (!repositoryState.isAtRoot && repositoryState.openedItemId) {
      scrollToItemFunction(repositoryState.openedItemId);
      return;
    }
  }, [repositoryState.openedRootItemId, repositoryState.openedItemId, data]);

  useEffect(() => {
    const currentIdItem = Number(repositoryState.currentArtifactData?.id);

    if (pageObjectResponse && associationCompleted) {
      dispatch(
        asyncActions.UPDATE_ARTIFACT_OR_RELEASE({
          rootId: repositoryState.rootId,
          id: currentIdItem,
          body: {
            ...repositoryState.currentArtifactData,
            pageIds: [pageObjectResponse.id],
          },
        }),
      );

      // Remove previous artifact id if was changing documentation
      const prevArtifacts = pageObjectResponse.artifactIds.filter(
        (artifact: any) => artifact.id !== currentIdItem,
      );

      const updatedPage = {
        ...pageObjectResponse,
        artifactIds: [...prevArtifacts, currentIdItem],
      };

      dispatch(
        asyncPageActions.UPDATE_A_PAGE({
          id: pageObjectResponse.id,
          payload: updatedPage,
        }),
      );

      dispatch(RepositorySlice.actions.RESET_PAGE_OBJECT_RESPONSE());
    }
  }, [pageObjectResponse, dispatch, repositoryState.rootId]);

  const formatDateColumn = (unformattedDate: string) => {
    const date = new Date(unformattedDate);

    if (isNaN(date.getTime())) {
      return;
    }

    const dateFormat = new Intl.DateTimeFormat(applicationLanguage, {
      dateStyle: 'long',
      timeStyle: 'long',
    } as Intl.DateTimeFormatOptions);

    const formattedDate = dateFormat.format(date);

    return formattedDate;
  };

  const showLateralInfo = () => {
    return (
      <Drawer
        title={t('repository-artifact-details-label')}
        placement="right"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        width={400}
      >
        {selectedRecord && (
          <div>
            <p>
              <span className="repository-details-title">
                {t('repository-artifact-name-label')}:{' '}
              </span>
              {selectedRecord.name}
            </p>
            {selectedRecord.description && (
              <p>
                <span className="repository-details-title">
                  {t('repository-artifact-description-label')}:{' '}
                </span>
                {selectedRecord.description}
              </p>
            )}
            {selectedRecord.createdByFullName && (
              <p>
                <span className="repository-details-title">
                  {t('repository-artifact-createdBy-label')}:{' '}
                </span>
                {selectedRecord.createdByFullName}
              </p>
            )}

            <p>
              <span className="repository-details-title">
                {t('repository-artifact-createdAt-label')}:{' '}
              </span>
              {formatDateColumn(selectedRecord.createdAt)}
            </p>
            <p>
              <span className="repository-details-title">
                {t('repository-artifact-last-modified-label')}:{' '}
              </span>
              {formatDateColumn(selectedRecord.updatedAt)}
            </p>
          </div>
        )}
      </Drawer>
    );
  };

  const onOpenLateralInfo = (dataInfoItem: any) => {
    setSelectedRecord(dataInfoItem);
    setDrawerVisible(true);
  };

  const setRepositoryNameToSearch = debounce((repositoryName: string) => {
    if (repositoryName.trim() === '') {
      return dispatch(
        asyncActions.CLEAR_SEARCH_REPOSITORY({
          rootId: repositoryState.rootId,
          parentId: releaseId,
          queryString: 'name',
        }),
      );
    }
    dispatch(
      asyncActions.FIND_REPOSITORY_BY_NAME({
        rootId: repositoryState.rootId,
        parentId: releaseId,
        name: repositoryName.trim(),
      }),
    );
  }, 500);

  const dispatchToScrollRealease = (record: any) => {
    dispatch(
      RepositorySlice.actions.SET_CLICKED_ITEM_ID(
        `${record.genericType}-${record.id}`,
      ),
    );
  };

  const goToRepositoryChildren = (record: any) => {
    setSearchValue('');
    if (repositoryState.isAtRoot) {
      dispatch(
        RepositorySlice.actions.SET_CLICKED_ROOT_ITEM_ID(
          `${record.genericType}-${record.id}`,
        ),
      );
    }
    navigate(`/repositories/${record.id}`);
  };

  const goToRootRepository = () => {
    dispatch(RepositorySlice.actions.SET_IS_AT_ROOT(true));
    dispatch(RepositorySlice.actions.SET_ROOT_ID(undefined));
    navigate('/repositories');
  };

  const handleCreateButtonMenuClick = (e: any) => {
    if (e.key === 'release') {
      dispatch(RepositorySlice.actions.OPEN_CREATE_RELEASE_MODAL());
    } else if (e.key === 'file') {
      dispatch(RepositorySlice.actions.OPEN_UPLOAD_MODAL());
    }
  };

  const downloadTransferredFile = () => {
    const url = window.URL.createObjectURL(
      new Blob([repositoryState.transferState.transferredData]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      repositoryState.transferState.transferredFileName,
    );
    document.body.appendChild(link);
    link.click();
  };

  const setDefaultFile = (id: number) => {
    dispatch(
      asyncActions.SET_DEFAULT_FILE({
        releaseId: releaseId,
        id: id,
      }),
    );
  };

  const getFileDownloadUrl = (id: number) => {
    const url = `${window.location.origin}/repositories/${releaseId}/download/${id}`;
    navigator.clipboard.writeText(url);

    messageNotification.successMessage(
      t('repository-download-link-copied-to-clipboard-title'),
      t('repository-download-link-copied-to-clipboard-description'),
    );
  };

  const onEditArtifict = (id: number) => {
    const item = repositoryState.artifacts?.content.find(
      (x) => x.id === id && x.genericType === 'ARTIFACT',
    );
    if (!item) {
      return;
    }
    dispatch(
      RepositorySlice.actions.OPEN_EDIT_ARTIFACT_MODAL({
        object: item,
        allowNameEdit: true,
      }),
    );
  };

  const onEditRelease = (id: number) => {
    const item = repositoryState.artifacts?.content.find(
      (x) => x.id === id && x.genericType === 'ARTIFACT',
    );
    if (!item) {
      return;
    }
    dispatch(
      RepositorySlice.actions.OPEN_EDIT_RELEASE_MODAL({
        object: item,
        allowNameEdit: true,
      }),
    );
  };

  const transferFile = (id: number, downloadAfterTransfer: boolean = false) => {
    abortControllerRef.current = new AbortController();
    dispatch(
      RepositorySlice.actions.SET_DOWNLOAD_AFTER_TRANSFER(
        downloadAfterTransfer,
      ),
    );
    dispatch(
      asyncActions.TRANSFER_FILE({
        rootId: repositoryState.rootId,
        id: id,
        abortSignal: abortControllerRef.current.signal,
      }),
    );
  };

  const cancelTransfer = () => {
    abortControllerRef.current.abort();
  };

  const onDownloadFile = (id: number, filename: string) => {
    dispatch(RepositorySlice.actions.OPEN_DOWNLOAD_MODAL(filename));
    transferFile(id, true);
  };

  const onPdfPreview = (item: any) => {
    dispatch(RepositorySlice.actions.OPEN_PREVIEW_PDF_MODAL(item.name));
    transferFile(item.id);
  };

  const onTxtPreview = (item: any) => {
    dispatch(RepositorySlice.actions.OPEN_PREVIEW_TXT_MODAL(item.name));
    transferFile(item.id);
  };

  const onOpenFile = (item: any) => {
    if (item.name.endsWith('.pdf')) {
      onPdfPreview(item);
    } else if (item.name.endsWith('.txt')) {
      onTxtPreview(item);
    }
  };

  const getIdFromString = (urlOrId: string): number | null => {
    // Get all digits from the string
    const numbers = urlOrId.match(/\d+/g);

    // If there are numbers, return the last one from the array converting it to the proper type.
    // If it fails, returns null.
    if (numbers) {
      return parseInt(numbers[numbers.length - 1]) || null;
    }

    return null;
  };

  const onMoveItem = (
    destinationUrlOrId: string,
    itemId: number,
    itemType: string,
  ): void => {
    const destinationArtifactId = getIdFromString(destinationUrlOrId);
    if (!destinationArtifactId) {
      messageNotification.errorMessage(
        t('repository-move-invalid-url-title'),
        t('repository-move-invalid-url-description'),
      );
      return;
    }

    if (
      repositoryState.currentArtifactId &&
      destinationArtifactId === +repositoryState.currentArtifactId
    ) {
      messageNotification.warningMessage(
        t('repository-move-same-folder-title'),
        t('repository-move-same-folder-description'),
      );
      return;
    }

    if (itemType === 'FILE') {
      dispatch(
        asyncActions.MOVE_FILE({
          destinationArtifactId: destinationArtifactId,
          fileId: itemId,
        }),
      );
      return;
    } else if (itemType === 'ARTIFACT') {
      dispatch(
        asyncActions.MOVE_ARTIFACT({
          destinationArtifactId: destinationArtifactId,
          artifactId: itemId,
        }),
      );
      return;
    }

    messageNotification.errorMessage(
      t('repository-move-unknown-item-type-title'),
      t('repository-move-unknown-item-type-description'),
    );
  };

  const onMoveItems = (
    destinationUrlOrId: string,
    fileIds: any,
    artifactIds: any,
  ) => {
    const destinationArtifactId = getIdFromString(destinationUrlOrId);
    if (!destinationArtifactId) {
      messageNotification.errorMessage(
        t('repository-move-invalid-url-title'),
        t('repository-move-invalid-url-description'),
      );
      return;
    }

    if (!fileIds.length && !artifactIds.length) {
      messageNotification.errorMessage(
        t('repository-move-unknown-item-type-title'),
        t('repository-move-unknown-item-type-description'),
      );
      return;
    }

    if (
      repositoryState.currentArtifactId &&
      destinationArtifactId === +repositoryState.currentArtifactId
    ) {
      messageNotification.warningMessage(
        t('repository-move-same-folder-title'),
        t('repository-move-same-folder-description'),
      );
      return;
    }

    if (fileIds.length && artifactIds.length) {
      dispatch(RepositorySlice.actions.SET_IS_MOVING_FILES_AND_ARTIFACTS(true));
    }

    if (fileIds.length) {
      dispatch(
        asyncActions.MOVE_FILES({
          destinationArtifactId: destinationArtifactId,
          fileIds: fileIds,
        }),
      );
    }

    if (artifactIds.length) {
      dispatch(
        asyncActions.MOVE_ARTIFACTS({
          destinationArtifactId: destinationArtifactId,
          artifactIds: artifactIds,
        }),
      );
    }
  };

  const showMoveButton = () => {
    const itemsCount = repositoryState.selectedRowsItems.length;
    if (!itemsCount || itemsCount === 1) {
      return;
    }

    const fileIds = repositoryState.selectedRowsItems
      .filter((element: any) => {
        return element.genericType === 'FILE';
      })
      .map((item: any) => {
        return item.id;
      });

    const artifactIds = repositoryState.selectedRowsItems
      .filter((element: any) => {
        return element.genericType === 'ARTIFACT';
      })
      .map((item: any) => {
        return item.id;
      });

    const itemsString =
      fileIds.length && artifactIds.length
        ? t('repository-move-items-button-items')
        : fileIds.length
          ? t('repository-move-items-button-files')
          : t('repository-move-items-button-artifacts');

    return (
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <Form
            name="moveUrlForm"
            layout="inline"
            autoComplete="off"
            onFinish={(values) => {
              onMoveItems(values.url, fileIds, artifactIds);
            }}
          >
            <Form.Item noStyle>
              <Input.Group compact>
                <Form.Item name="url" rules={[{ required: true }]} noStyle>
                  <Input
                    style={{ width: '200px' }}
                    placeholder={t('repository-move-url-placeholder')}
                  />
                </Form.Item>
                <Form.Item noStyle>
                  <Button type="primary" htmlType="submit">
                    <RightCircleOutlined />
                  </Button>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Form>
        }
      >
        <Button
          id="move-selected-items"
          shape="round"
          className="move-selected-button"
        >
          <Space>
            <Move style={{ width: '15px', height: '15px' }} />
            {`${t('repository-move-items-button-prefix')} ${itemsCount} ${itemsString}`}
          </Space>
        </Button>
      </Popover>
    );
  };

  const showCreateButton = () => {
    if (!isUserAdmin && repositoryState.isAtRoot) {
      return;
    }

    if (isUserAdmin && repositoryState.isAtRoot) {
      return (
        <Button
          id="create-repository-button"
          name="new-repository"
          type="primary"
          shape="round"
          ghost
          onClick={() =>
            dispatch(RepositorySlice.actions.OPEN_CREATE_ARTIFACT_MODAL())
          }
        >
          {t('repository-create-button')}
        </Button>
      );
    }

    if (
      !isUserAdmin &&
      !repositoryState.currentArtifactPermissions.includes('WRITE')
    ) {
      return;
    }

    const items: MenuProps['items'] = [
      {
        label: t('repository-create-release-button'),
        key: 'release',
        icon: <FolderAddFilled />,
      },
      {
        label: t('repository-create-file-button'),
        key: 'file',
        icon: <FileAddFilled />,
      },
    ];

    const menuProps = {
      items,
      onClick: handleCreateButtonMenuClick,
    };

    return (
      <Dropdown menu={menuProps}>
        <Button type="primary" shape="round" ghost>
          <Space>
            {t('repository-create-button')}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    );
  };

  const showSearchInputAndNewButton = () => {
    return (
      <Col span={22} className="repository-buttons-col">
        <Col>
          <Search
            type="text"
            name="search-button"
            className="search-button"
            placeholder={t(`repository-listing-search`)}
            onSearch={(x) => setRepositoryNameToSearch(x)}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setRepositoryNameToSearch(e.target.value);
            }}
            value={searchValue}
          />
        </Col>
        {showMoveButton()}
        {loginState.loggedIn && showCreateButton()}
      </Col>
    );
  };

  const handleBreadCrumbClick = (item: any) => {
    const breadCrumbIndex =
      repositoryState.breadcrumb &&
      repositoryState.breadcrumb.findIndex((x) => x.id === item.id);

    if (breadCrumbIndex === 0 && repositoryState.breadcrumb) {
      const firtsItem = {
        id: repositoryState.breadcrumb[1].id,
        name: repositoryState.breadcrumb[1].name,
        genericType: 'ARTIFACT',
      };
      dispatchToScrollRealease(firtsItem);
    }
    if (breadCrumbIndex && repositoryState.breadcrumb) {
      const next = repositoryState.breadcrumb[breadCrumbIndex + 1];
      const nextItem = {
        id: next.id,
        name: next.name,
        genericType: 'ARTIFACT',
      };
      dispatchToScrollRealease(nextItem);
    }
    goToRepositoryChildren(item);
  };

  const showBreadcrumb = () => {
    if (!releaseId || !repositoryState.breadcrumb) {
      return;
    }

    return (
      <Row>
        <Col span={24} className="repository-breadcrumb-col">
          <Breadcrumb separator=">">
            <Breadcrumb.Item
              className="repository-breadcrumb-link"
              onClick={() => goToRootRepository()}
            >
              <HomeOutlined />
            </Breadcrumb.Item>
            {repositoryState.breadcrumb.map((item) => {
              const isCurrentFolder =
                item.name === repositoryState.currentArtifactData?.name;

              return (
                <Breadcrumb.Item
                  key={`bc-${item.id}`}
                  onClick={() => {
                    if (!isCurrentFolder) {
                      handleBreadCrumbClick(item);
                    }
                  }}
                >
                  <span
                    style={{ fontWeight: isCurrentFolder ? 'bold' : '' }}
                    className={
                      isCurrentFolder
                        ? 'repository-breadcrumb-link-current'
                        : 'repository-breadcrumb-link'
                    }
                  >
                    {item.name}
                  </span>
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </Col>
      </Row>
    );
  };

  const defaultSortBehaviour: IDefaultSortBehaviour = {
    columnProperty: 'name',
    order: 'ascend',
    disableDefaultSortBehaviour: () => {
      if (repositoryState.repositoryFirstQueryBehaviour) {
        dispatch(
          RepositorySlice.actions.REPOSITORY_DISABLE_DEFAULT_SORT_BEHAVIOUR(),
        );
      }
    },
  };

  const dispatchSortBy = (sortBy: string) => {
    if (!repositoryState.isAtRoot) {
      dispatch(
        asyncActions.GET_CONTENT_BY_ID({
          rootId: repositoryState.rootId,
          parentId: releaseId,
          queryString: sortBy,
        }),
      );
      return;
    }
    dispatch(asyncActions.GET_ALL_ARTIFACTS(sortBy));
  };

  const onChangeSortRepository = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    let sortBy = '';

    if (!order || !field) {
      return dispatchSortBy(sortBy);
    }

    if (defaultSortBehaviour) {
      defaultSortBehaviour.disableDefaultSortBehaviour();
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;

    switch (fieldToQueryParam) {
      case 'updatedAt':
        sortBy =
          order === 'ascend' ? fieldToQueryParam : `${fieldToQueryParam},desc`;
        return dispatchSortBy(sortBy);

      default:
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);
    }
  };

  const showTableRepository = () => {
    if (!data) {
      return;
    }
    return (
      <Row>
        <Col span={24}>
          <RepositoryTable
            emptyText={t('repository-empty-table')}
            dataSource={data!}
            tableLoading={repositoryState.isLoading}
            onChangeSort={onChangeSortRepository}
            rowClickAction={(record: any) => {
              if (record.genericType === 'FILE') {
                onOpenFile(record);
                return;
              }
              goToRepositoryChildren(record);
            }}
            selectRowsToGivenTab={(rows: any) => {
              dispatch(RepositorySlice.actions.SET_SELECTED_ROWS_ITEMS(rows));
            }}
            selectIdFromRowsSelected={() => {}}
            onMoveItem={onMoveItem}
            disableOptionsColumn={!repositoryState.isAtRoot}
            onOpenLateralInfo={onOpenLateralInfo}
            onEditArtifict={onEditArtifict}
            onEditRelease={onEditRelease}
            defaultFileId={defaultFileId}
            setDefaultFile={setDefaultFile}
            getFileDownloadUrl={getFileDownloadUrl}
            downloadFile={onDownloadFile}
            deleteItem={showDeleteModal}
            formatDateColumn={formatDateColumn}
            defaultSortBehaviour={defaultSortBehaviour}
            isUserAdmin={isUserAdmin}
          />
        </Col>
      </Row>
    );
  };

  const showCreateModal = () => {
    return (
      <RepositoryCreateEditModal
        open={repositoryState.openCreateModal}
        allowNameEdit={true}
        title={
          repositoryState.creatEditType === RepositoryContentTypes.Artifact
            ? t('repository-create-artifact-modal-title')
            : t('repository-create-release-modal-title')
        }
        okText={t('repository-create-edit-modal-save-label')}
        cancelText={t('repository-create-edit-modal-cancel-label')}
        nameText={t('repository-create-edit-modal-name-label')}
        descriptionText={t(
          'repository-create-edit-modal-description-label-max-length',
        )}
        confirmLoading={repositoryState.isCreatingOrSavingArtifactOrRelease}
        onCancel={() => {
          dispatch(RepositorySlice.actions.CLOSE_CREATE_MODAL());
        }}
        onOk={(values) => {
          if (
            repositoryState.creatEditType === RepositoryContentTypes.Artifact
          ) {
            dispatch(
              asyncActions.NEW_ARTIFACT({
                name: values.name,
                description: values.description,
                publicAccess: false,
              }),
            );
          } else {
            dispatch(
              asyncActions.NEW_RELEASE({
                rootId: repositoryState.rootId,
                id: releaseId,
                body: {
                  name: values.name,
                  description: values.description,
                },
              }),
            );
          }
        }}
      />
    );
  };

  const showEditModal = () => {
    const item = repositoryState.objectToEdit;
    if (!item) {
      return;
    }

    return (
      <RepositoryCreateEditModal
        open={repositoryState.openEditModal}
        allowNameEdit={repositoryState.editModalAllowNameEdit}
        title={
          repositoryState.creatEditType === RepositoryContentTypes.Artifact
            ? t('repository-edit-artifact-modal-title')
            : t('repository-edit-release-modal-title')
        }
        okText={t('repository-create-edit-modal-save-label')}
        cancelText={t('repository-create-edit-modal-cancel-label')}
        nameText={t('repository-create-edit-modal-name-label')}
        descriptionText={t(
          'repository-create-edit-modal-description-label-max-length',
        )}
        confirmLoading={repositoryState.isCreatingOrSavingArtifactOrRelease}
        currentName={item.name}
        currentDescription={item.description}
        onCancel={() => {
          dispatch(RepositorySlice.actions.CLOSE_EDIT_MODAL());
        }}
        onOk={(values) => {
          const name = repositoryState.editModalAllowNameEdit
            ? values.name
            : item.name;
          // File editing is not supported
          if (repositoryState.creatEditType === RepositoryContentTypes.File) {
            return;
          }

          let itemToBeUpdatedBody =
            repositoryState.creatEditType === RepositoryContentTypes.Artifact
              ? (item as IArtifactResponse)
              : (item as IContent);

          dispatch(
            asyncActions.UPDATE_ARTIFACT_OR_RELEASE({
              rootId: item.id,
              id: item.id,
              body: {
                ...itemToBeUpdatedBody,
                name: name,
                description: values.description,
              },
              type: repositoryState.creatEditType,
            }),
          );
        }}
      />
    );
  };

  const showDeleteModal = (id: number) => {
    const item = repositoryState.artifacts?.content.find((x) => x.id === id);
    if (!item) {
      return;
    }

    const deleteType =
      item.genericType === 'FILE'
        ? RepositoryContentTypes.File
        : repositoryState.isAtRoot
          ? RepositoryContentTypes.Artifact
          : RepositoryContentTypes.Release;

    const modalTitle =
      deleteType === RepositoryContentTypes.File
        ? 'repository-file-delete-modal-title'
        : repositoryState.isAtRoot
          ? 'repository-artifact-delete-modal-title'
          : 'repository-release-delete-modal-title';

    showModal(
      'warning',
      `${t(modalTitle)}`,
      `${t('repository-artifact-delete-modal-label')}`,
      () => {
        dispatch(RepositorySlice.actions.SET_DELETE_TYPE(deleteType));
        // FIXME: not sure about the rootId
        dispatch(
          asyncActions.DELETE_DATA({
            rootId: repositoryState.isAtRoot ? item.id : repositoryState.rootId,
            id: item.id,
            type: item.genericType,
          }),
        );
        if (searchValue) {
          return setRepositoryNameToSearch(searchValue);
        }
      },
      () => {},
      `${t('repository-delete-modal-delete-label')}`,
      `${t('repository-delete-modal-cancel-label')}`,
    );
  };

  const showDownloadModal = () => {
    return (
      <RepositoryDownloadModal
        open={repositoryState.openDownloadModal}
        title={t('repository-download-modal-title')}
        okText={t('repository-download-modal-close-label')}
        cancelText={t('repository-download-modal-cancel-label')}
        fileName={repositoryState.downloadingFileName}
        percent={repositoryState.transferState.transferProgress}
        loading={repositoryState.transferState.isTransferring}
        onOk={() => {
          dispatch(RepositorySlice.actions.CLOSE_DOWNLOAD_MODAL());
        }}
        onCancel={() => {
          cancelTransfer();
          dispatch(RepositorySlice.actions.CLOSE_DOWNLOAD_MODAL());
        }}
      />
    );
  };

  const showUploadModal = () => {
    return (
      <RepositoryUploadModal
        open={repositoryState.openUploadModal}
        title={t('repository-upload-modal-title')}
        okText={t('repository-upload-modal-close-label')}
        cancelText={t('repository-upload-modal-cancel-label')}
        draggerText={t('repository-upload-modal-dragger-text')}
        draggerHintText={t('repository-upload-modal-dragger-description')}
        loading={repositoryState.transferState.isTransferring}
        rootId={repositoryState.rootId}
        releaseId={+releaseId!}
        onClose={() => {
          dispatch(RepositorySlice.actions.CLOSE_UPLOAD_MODAL());
        }}
      />
    );
  };

  const showPreviewPdfModal = () => {
    return (
      <RepositoryViewPdfModal
        open={repositoryState.openPreviewPdfModal}
        fileName={repositoryState.downloadingFileName}
        title={t('repository-preview-modal-title')}
        downloadText={t('repository-preview-modal-download-label')}
        closeText={t('repository-preview-modal-close-label')}
        cancelText={t('repository-preview-modal-cancel-label')}
        progress={repositoryState.transferState.transferProgress}
        loading={repositoryState.transferState.isTransferring}
        onOk={() => {
          downloadTransferredFile();
        }}
        onCancel={() => {
          cancelTransfer();
          dispatch(RepositorySlice.actions.CLOSE_PREVIEW_PDF_MODAL());
        }}
        fileData={repositoryState.transferState.transferredData}
      />
    );
  };

  const showPreviewTxtModal = () => {
    return (
      <RepositoryViewTxtModal
        open={repositoryState.openPreviewTxtModal}
        fileName={repositoryState.downloadingFileName}
        title={t('repository-preview-modal-title')}
        downloadText={t('repository-preview-modal-download-label')}
        closeText={t('repository-preview-modal-close-label')}
        cancelText={t('repository-preview-modal-cancel-label')}
        progress={repositoryState.transferState.transferProgress}
        loading={repositoryState.transferState.isTransferring}
        onOk={() => {
          downloadTransferredFile();
        }}
        onCancel={() => {
          cancelTransfer();
          dispatch(RepositorySlice.actions.CLOSE_PREVIEW_TXT_MODAL());
        }}
        fileData={repositoryState.transferState.transferredData}
      />
    );
  };

  const hideAssociationPopover = () => {
    setOpenAssociationPopover(false);
    setAssociationCompleted(true);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpenAssociationPopover(newOpen);
  };

  const verifyUrl = (url: string): boolean => {
    // Regular expression to check if the URL starts with "http" and ends with "/pages/:slug"
    const urlPattern = /^http.*\/pages\/[a-zA-Z0-9-]+$/;
    return urlPattern.test(url);
  };

  const onAssociationDocumentation = (urlOrSlug: string) => {
    const isAnUrl = verifyUrl(urlOrSlug);
    const duplicateLength = (urlOrSlug.match(/http/g) || []).length;

    if (duplicateLength > 1) {
      messageNotification.errorMessage(
        t('repository-documentation-association-invalid-url-title'),
        t('repository-documentation-association-invalid-url-description'),
      );
      return;
    }

    if (isAnUrl) {
      const slug = urlOrSlug.slice(urlOrSlug.lastIndexOf('/pages/') + 7);

      dispatch(
        asyncActions.GET_PAGE_BY_SLUG({
          slug,
        }),
      );
      return;
    }

    const slug = urlOrSlug.trim();
    dispatch(
      asyncActions.GET_PAGE_BY_SLUG({
        slug,
      }),
    );
  };

  const removeDocumentAssociation = () => {
    const currentIdItem = repositoryState.currentArtifactData?.id;
    const pageData = repositoryState.currentArtifactData?.pages[0];
    dispatch(
      asyncActions.UPDATE_ARTIFACT_OR_RELEASE({
        rootId: repositoryState.rootId,
        id: currentIdItem,
        body: {
          ...repositoryState.currentArtifactData,
          pageIds: [],
        },
      }),
    );

    const updatedArtifactIdsList = pageData.artifactIds?.filter(
      (artifactId: any) => artifactId !== currentIdItem,
    );

    const updatedPage = {
      ...pageData,
      artifactIds:
        updatedArtifactIdsList === undefined ? [] : updatedArtifactIdsList,
    };

    dispatch(
      asyncPageActions.UPDATE_A_PAGE({
        id: pageData.id,
        payload: updatedPage,
      }),
    );

    dispatch(RepositorySlice.actions.RESET_PAGE_OBJECT_RESPONSE());
  };

  const associationPopoverContent = () => {
    return (
      <Form
        form={form}
        name="associationUrlForm"
        layout="inline"
        autoComplete="off"
        onFinish={(values) => {
          onAssociationDocumentation(values.url);
          hideAssociationPopover();
          form.resetFields();
        }}
      >
        <Form.Item noStyle>
          <Input.Group compact>
            <Form.Item name="url" rules={[{ required: true }]} noStyle>
              <Input
                style={{ width: '200px' }}
                placeholder={t(
                  'repository-documentation-association-url-placeholder',
                )}
              />
            </Form.Item>
            <Form.Item noStyle>
              <Button type="primary" htmlType="submit">
                <RightCircleOutlined />
              </Button>
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Form>
    );
  };

  const showHeader = () => {
    if (repositoryState.isAtRoot) {
      return (
        <Header title={t('header-repository')} subtitle="" showEllipsis>
          <Row>{t('repository-subtitle')}</Row>
        </Header>
      );
    }

    const associatedDocument = repositoryState?.currentArtifactData?.pages[0];

    // FIXME: If navbar language doesn't have documentation (use default)
    const associatedDocumentContent =
      associatedDocument &&
      associatedDocument.pageContents.find(
        (page: any) => page.language === defaultLanguage,
      );

    return (
      <Row className="Header">
        <Col className="left-column" span={16}>
          <Row>
            <Col className="left-column-header" span={16}>
              <hr className="left-column-header-divider" />
              <h3 className="left-column-header-title">
                {repositoryState.currentArtifactData?.name}
              </h3>
              {showBreadcrumb()}
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Space>
                <span className="repository-description-title">
                  {t('repository-description-title')}
                </span>
                {(isUserAdmin ||
                  repositoryState.currentArtifactPermissions.includes(
                    'WRITE',
                  )) && (
                  <Link
                    disabled={repositoryState.isLoading}
                    onClick={() => {
                      dispatch(
                        RepositorySlice.actions.OPEN_EDIT_RELEASE_MODAL({
                          object: repositoryState.currentArtifactData,
                          allowNameEdit: false,
                        }),
                      );
                    }}
                  >
                    {t('repository-description-edit-button-title')}
                  </Link>
                )}
              </Space>
            </Col>
            <Col span={16}>
              {repositoryState.currentArtifactData &&
              repositoryState.currentArtifactData.description
                ? repositoryState.currentArtifactData.description
                : t('repository-description-not-available')}
            </Col>
          </Row>
        </Col>
        <Col className="right-column" span={8}>
          <div className="repository-header-documentation">
            <Row align="middle" style={{ fontWeight: 'bold' }}>
              {t('repository-documentation-association-title')}
            </Row>
            {associatedDocument ? (
              <>
                <Button
                  type="link"
                  onClick={() => navigate(`/pages/${associatedDocument.slug}`)}
                >
                  {associatedDocumentContent.title}
                </Button>
                <Row align="middle" justify="space-evenly" gutter={8}>
                  <Popover
                    content={associationPopoverContent}
                    trigger="click"
                    open={openAssociationPopover}
                    onOpenChange={handleOpenChange}
                  >
                    <Button
                      type="primary"
                      shape="round"
                      ghost
                      style={{ marginRight: '.5rem' }}
                    >
                      {t('repository-change-documentation-association-button')}
                    </Button>
                  </Popover>
                  <Button
                    type="primary"
                    shape="round"
                    ghost
                    style={{ marginLeft: '.5rem' }}
                    onClick={() => removeDocumentAssociation()}
                  >
                    {t('repository-remove-documentation-association-button')}
                  </Button>
                </Row>
              </>
            ) : (
              <>
                <p>{t('repository-any-documentation-associated')}</p>
                <Popover
                  content={associationPopoverContent}
                  trigger="click"
                  open={openAssociationPopover}
                  onOpenChange={handleOpenChange}
                  className="evaluation-button"
                >
                  <Button
                    type="primary"
                    shape="round"
                    ghost
                    className="evaluation-button"
                  >
                    {t('repository-documentation-association-button')}
                  </Button>
                </Popover>
              </>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Col pull={1} span={22} push={1} id="repository-root-list-id">
      <div>
        {showHeader()}
        {showSearchInputAndNewButton()}
      </div>
      {showTableRepository()}
      {showLateralInfo()}
      {showCreateModal()}
      {showEditModal()}
      {showDownloadModal()}
      {showUploadModal()}
      {showPreviewPdfModal()}
      {showPreviewTxtModal()}
    </Col>
  );
};
