import { securedBaseApi } from './api.config';

export interface IClassification {
  id: number;
  name?: string;
  subClassifications?: IClassification[];
  enabled?: boolean;
  pagesIds: number[] | undefined;
  level?: number;
}

export interface INewClassificationParentBody {
  id: number;
}

export interface INewClassificationBody {
  name: string;
  parent: INewClassificationParentBody;
  level: number;
}

export interface IRenameClassificationBody {
  name: string;
}

export const classificationApi = {
  getAllClassifications: () => {
    return securedBaseApi.get<IClassification[]>('/classifications');
  },
  getClassifications: (isEnable: boolean = true) => {
    return securedBaseApi.get<IClassification[]>(
      `classifications/enabled?enabled=${isEnable}`,
    );
  },
  getChildrenClassifications: (parentId: number) => {
    return securedBaseApi.get<IClassification[]>(
      `/classifications/parent/${parentId}`,
    );
  },
  createNewClassification: (newClassificationBody: INewClassificationBody) => {
    return securedBaseApi.post<INewClassificationBody>(
      '/classifications',
      newClassificationBody,
    );
  },
  renameClassification: (id: number, newName: IRenameClassificationBody) => {
    return securedBaseApi.patch(`/classifications/rename/${id}`, newName);
  },
  disableClassification: (id: number) => {
    return securedBaseApi.put(`/classifications/disable/${id}`);
  },
  enableClassification: (id: number) => {
    return securedBaseApi.put(`/classifications/enable/${id}`);
  },
  deleteClassification: (id: number) => {
    return securedBaseApi.delete(`/classifications/${id}`);
  },
};
