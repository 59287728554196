import React from 'react';
import {
  FileExcelTwoTone,
  FileOutlined,
  FilePdfOutlined,
  FilePptTwoTone,
  FileTextOutlined,
  FileWordTwoTone,
  FileZipTwoTone,
  FolderOpenTwoTone,
  PictureTwoTone,
} from '@ant-design/icons';

interface IFileName {
  fileName: string;
  genericType: string;
}

export const Icon: React.FC<IFileName> = ({
  fileName,
  genericType,
}: IFileName) => {
  const icons = {
    apk: () => {
      return (
        <PictureTwoTone
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#003399',
            cursor: 'pointer',
          }}
        />
      );
    },
    excel: () => {
      return (
        <FileExcelTwoTone
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#46b051',
            cursor: 'pointer',
          }}
        />
      );
    },
    img: () => {
      return (
        <PictureTwoTone
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#003399',
            cursor: 'pointer',
          }}
        />
      );
    },
    folder: () => {
      return (
        <FolderOpenTwoTone
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#003399',
            cursor: 'pointer',
          }}
        />
      );
    },
    pdf: () => {
      return (
        <FilePdfOutlined
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#e30613',
            cursor: 'pointer',
          }}
        />
      );
    },
    ppt: () => {
      return (
        <FilePptTwoTone
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#003399',
            cursor: 'pointer',
          }}
        />
      );
    },
    txt: () => {
      return (
        <FileTextOutlined
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#575665',
            cursor: 'pointer',
          }}
        />
      );
    },
    word: () => {
      return (
        <FileWordTwoTone
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#003399',
            cursor: 'pointer',
          }}
        />
      );
    },
    zip: () => {
      return (
        <FileZipTwoTone
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#9D50B6',
            cursor: 'pointer',
          }}
        />
      );
    },
    defaultFile: () => {
      return (
        <FileOutlined
          style={{
            marginRight: 8,
            fontSize: '1.5rem',
            color: '#575665',
            cursor: 'pointer',
          }}
        />
      );
    },
  };

  const extensionMap = {
    '.png': icons.img,
    '.jpg': icons.img,
    '.JPG': icons.img,
    '.jpeg': icons.img,
    '.pdf': icons.pdf,
    '.apk': icons.apk,
    '.xlsx': icons.excel,
    '.xls': icons.excel,
    '.doc': icons.word,
    '.docx': icons.word,
    '.ppt': icons.ppt,
    '.pptx': icons.ppt,
    '.zip': icons.zip,
    '.rar': icons.zip,
    '.7z': icons.zip,
    '.txt': icons.txt,
  };

  const getIcon = () => {
    if (genericType === 'ARTIFACT') {
      return icons.folder();
    }

    // Regex to check if the file name has a valid extension that ends with letters from a-z
    // or numbers from 0-9, with the first character being a letter.
    const hasValidExtension = /\.[a-z][0-9a-z]*$/i;

    for (const [extension, iconFunc] of Object.entries(extensionMap)) {
      if (hasValidExtension.test(fileName) && fileName.endsWith(extension)) {
        return iconFunc();
      }
    }

    return icons.defaultFile();
  };

  return <>{getIcon()}</>;
};
