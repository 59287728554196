import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Row } from 'antd';

import { LoginForm } from '../../components/LoginForm/LoginForm';
import { ILoginRequest } from '../../interfaces/services-interfaces/ILoginRequest';
import { AppDispatch, RootState } from '../../store/store';
import { asyncActions, LoginSlice } from './Login.Slice';
import { RequestAccessSlice } from '../RequestAccess/RequestAccess.Slice';
import { ReactComponent as IngenicoLogo } from '../../assets/ingenico_gray_logo.svg';
import './Login.scss';

export const Login: React.FC = () => {
  const loginState = useSelector((state: RootState) => state.login);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { actions } = LoginSlice;

  useEffect(() => {
    if (loginState.loggedIn === false && loginState.authenticated === true) {
      dispatch(asyncActions.ME());
    }
  }, [dispatch, loginState.loggedIn, loginState.authenticated]);

  useEffect(() => {
    dispatch(RequestAccessSlice.actions.BACK_TO_ORIGINAL_STATE());
  }, [dispatch, actions]);

  useEffect(() => {
    if (loginState.loggedIn === false && loginState.user) {
      dispatch(actions.LOGGEDIN({ loggedState: true }));
      i18n.changeLanguage(loginState.user.preferenceLanguage);
    }

    if (loginState.loggedIn) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      if (urlSearchParams.has('repositoryRedirect')) {
        navigate('/repositories');
      } else if (loginState.user?.role === 'A') {
        navigate('/administrative-panel?tab=requests');
      } else {
        navigate('/home');
      }
    }
  }, [dispatch, actions, history, loginState.loggedIn, loginState.user, i18n]);

  const loginRequest = (loginBody: ILoginRequest) => {
    dispatch(asyncActions.LOGIN(loginBody));
  };

  return (
    <Col
      id="login-container"
      span={22}
      pull={1}
      push={1}
      className="LoginContainer"
    >
      <Row className="Login" justify="center">
        <Row className="header-columns">
          <Col span={11} className="login-column-header">
            <hr className="login-column-header-divider" />
            <h3 className="login-column-header-title"> {t('login-header')} </h3>
          </Col>
          <Col span={11} offset={2} className="login-column-header">
            <hr className="login-column-header-divider" />
            <h3 className="login-column-header-title">
              {t('request-access-header')}
            </h3>
          </Col>
        </Row>
        <Row className="login-request-row">
          <Col span={11} className="login-row">
            <LoginForm
              loginRequest={loginRequest}
              loginLoading={loginState.loginLoading}
            />
          </Col>
          <Col className="login-divider-column">
            <Divider type="vertical" />
          </Col>
          <Col span={11} className="request-text-button-col">
            <Row justify="center">
              <Row className="login-request-text">
                {t('login-label-dont-own-an-account')}
              </Row>
              <Row className="login-request-button">
                <Button
                  type="primary"
                  shape="round"
                  className="request-button"
                  onClick={() => navigate('/request-access')}
                >
                  {t('login-label-request-access')}
                </Button>
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className="IngenicoLogo">
          <IngenicoLogo />
        </Row>
      </Row>
    </Col>
  );
};
