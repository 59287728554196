import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  Grid,
  Layout,
  Menu,
  Divider,
  Avatar as AntdAvatar,
} from 'antd';

import {
  NavbarSlice,
  asyncActions as navbarAsyncActions,
} from './Navbar.Slice';
import { LoginSlice, asyncActions } from '../../Login/Login.Slice';

import { UsersSlice } from '../../../pages/AdministrativePanel/Users/Users.Slice';
import { CompanyAdministrativePanelSlice } from '../../../pages/AdministrativePanel/Companies/CompanyAdministrativePanel.Slice';
import { NewsSlice } from '../../../pages/AdministrativePanel/News/News.Slice';
import { TermsListingSlice } from '../../../pages/AdministrativePanel/Terms/TermsListing/TermListing.Slice';
import { PermissionsListingSlice } from '../../../pages/AdministrativePanel/Permissions/PermissionsListing/PermissionsListing.Slice';
import { LogsListingSlice } from '../../../pages/AdministrativePanel/Logs/LogsListing/LogsListing.Slice';
import { PendingRequestsSlice } from '../../../pages/AdministrativePanel/Requests/PendingRequests/PendingRequests.Slice';
import { ConcludedRequestsSlice } from '../../../pages/AdministrativePanel/Requests/ConcludedRequests/ConcludedRequests.Slice';

import { AppDispatch, RootState, store } from '../../../store/store';

import { ReactComponent as IngenicoLogo } from '../../../assets/ingenico_gray_logo.svg';
import { ReactComponent as DividerSvg } from '../../../assets/divider.svg';
import { ReactComponent as BrazilFlag } from '../../../assets/i18n_ptbr.svg';
import { ReactComponent as USAFlag } from '../../../assets/i18n_enus.svg';
import { ReactComponent as SpainFlag } from '../../../assets/i18n_spsp.svg';
import { ReactComponent as LogoutSvg } from '../../../assets/avatar-dropdown/logout.svg';
import { ReactComponent as UsersSvg } from '../../../assets/avatar-dropdown/users.svg';
import { ReactComponent as CompaniesSvg } from '../../../assets/avatar-dropdown/companies.svg';
import { ReactComponent as NewsSvg } from '../../../assets/avatar-dropdown/news.svg';
import { ReactComponent as RequestsSvg } from '../../../assets/avatar-dropdown/requests.svg';
import { ReactComponent as TermsSvg } from '../../../assets/avatar-dropdown/terms.svg';
import { ReactComponent as PermissionsSvg } from '../../../assets/avatar-dropdown/permissions.svg';
import { ReactComponent as LogsSvg } from '../../../assets/avatar-dropdown/logs.svg';
import { ReactComponent as ProfileSvg } from '../../../assets/avatar-dropdown/profile.svg';

import './Navbar.scss';
import { showModal } from '../../../helpers/showModal/showModal';
import { toTitleCase } from '../../../helpers/toTitleCase/toTitleCase';
import {
  BookOutlined,
  MessageFilled,
  MenuOutlined,
  MailOutlined,
} from '@ant-design/icons';
import {
  checkChangesInCompanyRegisterOrEdit,
  checkChangesInUserRegisterOrEdit,
  checkChangesInNewsRegisterOrEdit,
} from '../../../helpers/newRegisterChangeChecker/newRegisterChangeChecker';
import { PendingRequestsBadge } from '../../../components/PendingRequestsBadge/PendingRequestsBadge';
import { RequestTrackerSlice } from '../../RequestTracker/RequestTracker.Slice';
import { checkChangesOnCreateOrEditTerms } from '../../../helpers/termsChangeChecker/termsChangeChecker';
import { myProfileChangesChecker } from '../../../helpers/myProfileChangesChecker/myProfileChangesChecker';
import { ActiveModalSlice } from '../../Home/ActiveModal.Slice';
import { uniqueId } from 'lodash';

export const Navbar: React.FC = () => {
  const loginState = useSelector((state: RootState) => state.login);
  const navbarState = useSelector((state: RootState) => state.navBar);
  const pagesState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pages,
  );
  const pageResponseState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.pageObjectResponse,
  );
  const pageEditorState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.editorOutputOnEditing,
  );
  const createReadEditPageState = useSelector(
    (state: RootState) => state.createReadEditPageSlice,
  );

  const [routerState, setRouterState] = useState(['']);
  const [menuKeyState, setMenuKeyState] = useState({
    pages: uniqueId(),
    repositories: uniqueId(),
    about: uniqueId(),
  });

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const whitelistedPages = [
    'login',
    'request-access',
    'request-access/concluded',
    'forgot-password',
    'changePassword',
    'create-password/concluded',
    'activate-account',
  ];
  const softlistedPages = [
    'home',
    'about',
    'created-pages',
    'pages',
    'repositories',
  ];

  const generateMenuKeys = () => {
    return { pages: uniqueId(), repositories: uniqueId(), about: uniqueId() };
  }

  useEffect(() => {
    i18n.changeLanguage(navbarState.defaultLanguage);
    dispatch(ActiveModalSlice.actions.RESET_TO_INITIAL_STATE());
  }, [i18n, dispatch, navbarState.defaultLanguage]);

  const getPageFormFieldsValuesOnCreate = () => {
    if (
      !window.location.href.includes('pages') ||
      createReadEditPageState.pageMode !== 'create'
    ) {
      return false;
    }

    const hasPageContentInState =
      createReadEditPageState.pageSlug ||
      pagesState.pageInPortuguese.title ||
      pagesState.pageInEnglish.title ||
      pagesState.pageInSpanish.title ||
      pagesState.pageInPortuguese.description ||
      pagesState.pageInEnglish.description ||
      pagesState.pageInSpanish.description ||
      pagesState.pageInPortuguese.contents ||
      pagesState.pageInEnglish.contents ||
      pagesState.pageInSpanish.contents;

    if (hasPageContentInState) {
      return true;
    }

    const pageTitleElement = document.getElementById('page-form-title') as any;
    if (pageTitleElement && pageTitleElement.value) {
      return true;
    }

    const pageDescriptionElement = document.getElementById(
      'page-form-description',
    ) as any;
    if (pageDescriptionElement && pageDescriptionElement.value) {
      return true;
    }

    return false;
  };

  const getPageFormFieldsValuesOnEdit = () => {
    if (
      !window.location.href.includes('pages') ||
      createReadEditPageState.pageMode !== 'edit'
    ) {
      return false;
    }

    const pagesInEveryLanguage = {
      pageInPortuguese: {
        ...pagesState.pageInPortuguese,
      },
      pageInEnglish: {
        ...pagesState.pageInEnglish,
      },
      pageInSpanish: {
        ...pagesState.pageInSpanish,
      },
    };

    const pageTitleElement = document.getElementById('page-form-title') as any;
    const pageDescriptionElement = document.getElementById(
      'page-form-description',
    ) as any;

    switch (pagesState.languageDropdown) {
      case 'PORTUGUESE':
        pagesInEveryLanguage.pageInPortuguese.title = pageTitleElement?.value;
        pagesInEveryLanguage.pageInPortuguese.description =
          pageDescriptionElement?.value;
        if (pageEditorState) {
          pagesInEveryLanguage.pageInPortuguese.contents = pageEditorState;
        }
        break;

      case 'ENGLISH':
        pagesInEveryLanguage.pageInEnglish.title = pageTitleElement?.value;
        pagesInEveryLanguage.pageInEnglish.description =
          pageDescriptionElement?.value;
        if (pageEditorState) {
          pagesInEveryLanguage.pageInEnglish.contents = pageEditorState;
        }
        break;

      case 'SPANISH':
        pagesInEveryLanguage.pageInSpanish.title = pageTitleElement?.value;
        pagesInEveryLanguage.pageInSpanish.description =
          pageDescriptionElement?.value;
        if (pageEditorState) {
          pagesInEveryLanguage.pageInSpanish.contents = pageEditorState;
        }
        break;

      default:
        break;
    }

    let showModalValidation = false;

    const pageResponseInPortuguese = pageResponseState?.pageContents.find(
      (x) => x.language === 'PORTUGUESE',
    );
    if (pageResponseInPortuguese) {
      if (
        pageResponseInPortuguese.title !==
          pagesInEveryLanguage.pageInPortuguese.title ||
        pageResponseInPortuguese.subtitle !==
          pagesInEveryLanguage.pageInPortuguese.description ||
        pageResponseInPortuguese.mainContent !==
          pagesInEveryLanguage.pageInPortuguese.contents
      ) {
        showModalValidation = true;
      }
    } else if (
      pagesInEveryLanguage.pageInPortuguese.title ||
      pagesInEveryLanguage.pageInPortuguese.description ||
      pagesInEveryLanguage.pageInPortuguese.contents
    ) {
      showModalValidation = true;
    }

    const pageResponseInEnglish = pageResponseState?.pageContents.find(
      (x) => x.language === 'ENGLISH',
    );
    if (pageResponseInEnglish) {
      if (
        pageResponseInEnglish.title !==
          pagesInEveryLanguage.pageInEnglish.title ||
        pageResponseInEnglish.subtitle !==
          pagesInEveryLanguage.pageInEnglish.description ||
        pageResponseInEnglish.mainContent !==
          pagesInEveryLanguage.pageInEnglish.contents
      ) {
        showModalValidation = true;
      }
    } else if (
      pagesInEveryLanguage.pageInEnglish.title ||
      pagesInEveryLanguage.pageInEnglish.description ||
      pagesInEveryLanguage.pageInEnglish.contents
    ) {
      showModalValidation = true;
    }

    const pageResponseInSpanish = pageResponseState?.pageContents.find(
      (x) => x.language === 'SPANISH',
    );
    if (pageResponseInSpanish) {
      if (
        pageResponseInSpanish.title !==
          pagesInEveryLanguage.pageInSpanish.title ||
        pageResponseInSpanish.subtitle !==
          pagesInEveryLanguage.pageInSpanish.description ||
        pageResponseInSpanish.mainContent !==
          pagesInEveryLanguage.pageInSpanish.contents
      ) {
        showModalValidation = true;
      }
    } else if (
      pagesInEveryLanguage.pageInSpanish.title ||
      pagesInEveryLanguage.pageInSpanish.description ||
      pagesInEveryLanguage.pageInSpanish.contents
    ) {
      showModalValidation = true;
    }

    return showModalValidation;
  };

  const getCompanyFormFieldsValueOnCreateOrEditMode = () => {
    if (
      !(
        window.location.href.includes('tab=companies') &&
        window.location.href.includes('add_or_edit')
      )
    ) {
      return false;
    }

    return checkChangesInCompanyRegisterOrEdit(
      window.location.href.includes('id=') ? 'edit' : 'create',
    );
  };

  const getUserFormFieldsValueOnCreateOrEditMode = () => {
    if (
      !(
        window.location.href.includes('tab=users') &&
        window.location.href.includes('add_or_edit')
      )
    ) {
      return false;
    }

    return checkChangesInUserRegisterOrEdit(
      window.location.href.includes('id=') ? 'edit' : 'create',
    );
  };

  const getNewsFormFieldsValueOnCreateOrEditMode = () => {
    if (
      !(
        window.location.href.includes('tab=news') &&
        window.location.href.includes('add_or_edit')
      )
    ) {
      return false;
    }

    return checkChangesInNewsRegisterOrEdit(
      window.location.href.includes('id=') ? 'edit' : 'create',
    );
  };

  const getTermsFieldsValueOnCreateOrEditMode = () => {
    if (
      !(
        window.location.href.includes('tab=terms') &&
        window.location.href.includes('add_or_edit')
      )
    ) {
      return false;
    }

    return checkChangesOnCreateOrEditTerms(
      window.location.href.includes('id=') ? 'edit' : 'create',
    );
  };

  const getMyProfileChanges = () => {
    if (!window.location.href.includes('my-profile')) {
      return false;
    }

    return myProfileChangesChecker();
  };

  const getPopUpChanges = () => {
    return (
      window.location.href.includes('tab=pop-up') &&
      store.getState().popUp.hasChanged
    );
  };

  const showDiscardChangesModal = (path: string) => {
    if (window.location.href.includes('my-profile') && path === 'my-profile') {
      return;
    }
    if (
      getPageFormFieldsValuesOnCreate() ||
      getPageFormFieldsValuesOnEdit() ||
      getCompanyFormFieldsValueOnCreateOrEditMode() ||
      getUserFormFieldsValueOnCreateOrEditMode() ||
      getNewsFormFieldsValueOnCreateOrEditMode() ||
      getTermsFieldsValueOnCreateOrEditMode() ||
      store.getState().permissionsByCompanies.listing.showCancelOrSaveChanges ||
      store.getState().permissionsByRepositories.listing
        .showCancelOrSaveChanges ||
      getMyProfileChanges() ||
      getPopUpChanges()
    ) {
      return showModal(
        'warning',
        t('documentation-confirmation-cancel-editing-title'),
        t('documentation-confirmation-cancel-editing-content'),
        () => {
          navigate(`/${path}`);
        },
        () => {
          setMenuKeyState(generateMenuKeys());
        },
      );
    } else {
      navigate(`/${path}`);
    }
  };

  useEffect(() => {
    if (loginState.finishedLoginAttempt && !loginState.user) {
      dispatch(
        NavbarSlice.actions.SET_LOADED_DEFAULT_LANGUAGE_AFTER_FAILED_LOGIN(),
      );
    }
  }, [dispatch, loginState.finishedLoginAttempt, loginState.user]);

  useEffect(() => {
    if (loginState.user && !loginState.user?.preferenceLanguage) {
      dispatch(
        navbarAsyncActions.PREFERENCELANGUAGE(navbarState.defaultLanguage),
      );
      dispatch(
        NavbarSlice.actions.CHANGE_DEFAULT_LANGUAGE(
          navbarState.defaultLanguage,
        ),
      );
    }
  }, [dispatch, loginState.user, navbarState.defaultLanguage]);

  useEffect(() => {
    if (loginState.user?.preferenceLanguage) {
      dispatch(
        NavbarSlice.actions.LOAD_USER_PREFERENCE_LANGUAGE(
          loginState.user?.preferenceLanguage,
        ),
      );
    }
  }, [dispatch, loginState.user]);

  useEffect(() => {
    if (whitelistedPages.find((x) => window.location.href.includes(x))) {
      return;
    }

    if (window.location.href.includes('?networkRedirector=true')) {
      return;
    }
    if (!loginState.loggedIn) {
      if (softlistedPages.find((x) => window.location.href.includes(x))) {
        dispatch(asyncActions.SOFTLOGIN());
        return;
      }
      dispatch(asyncActions.QUICKLOGIN());
    }
  }, [
    window.location.href,
    loginState.loggedIn,
    dispatch,
    asyncActions.QUICKLOGIN,
    asyncActions.SOFTLOGIN,
  ]);

  // Set the selected navbar item to the item corresponding to the key
  useEffect(() => {
    if (window.location.href.includes('created-pages') ||
        window.location.href.includes('pages')) {
      setRouterState([menuKeyState.pages]);
    } else if (window.location.href.includes('repositories')) {
      setRouterState([menuKeyState.repositories]);
    } else if (window.location.href.includes('about')) {
      setRouterState([menuKeyState.about]);
    } else {
      setRouterState(['']);
    }
  }, [window.location.href, setRouterState, menuKeyState]);

  const handleLanguageMenuItemClick = (language: string) => {
    if (loginState.loggedIn) {
      dispatch(navbarAsyncActions.PREFERENCELANGUAGE(language));
    }
    dispatch(NavbarSlice.actions.CHANGE_DEFAULT_LANGUAGE(language));
  };

  const generateHeaderColumns = () => {
    const dispatchAllLogoutOperations = () => {
      dispatch(LoginSlice.actions.LOGOUT());
      dispatch(RequestTrackerSlice.actions.RESET_NUMBER_OF_PENDING_REQUESTS());

      dispatch(UsersSlice.actions.USER_BACK_TO_ORIGINAL_STATE());
      dispatch(
        CompanyAdministrativePanelSlice.actions.COMPANY_BACK_TO_ORIGINAL_STATE(),
      );
      dispatch(NewsSlice.actions.NEWS_BACK_TO_ORIGINAL_STATE());
      dispatch(TermsListingSlice.actions.RESET_TO_ORIGINAL_STATE());
      dispatch(PermissionsListingSlice.actions.RESET_TO_INITIAL_STATE());
      dispatch(LogsListingSlice.actions.LOGS_BACK_TO_ORIGINAL_STATE());
      dispatch(
        PendingRequestsSlice.actions.PENDING_REQUESTS_BACK_TO_ORIGINAL_STATE(),
      );
      dispatch(
        ConcludedRequestsSlice.actions.CONCLUDED_REQUESTS_BACK_TO_ORIGINAL_STATE(),
      );

      navigate('/home');
    };

    const logout = () => {
      if (
        getPageFormFieldsValuesOnCreate() ||
        getPageFormFieldsValuesOnEdit() ||
        getCompanyFormFieldsValueOnCreateOrEditMode() ||
        getUserFormFieldsValueOnCreateOrEditMode() ||
        getNewsFormFieldsValueOnCreateOrEditMode() ||
        getTermsFieldsValueOnCreateOrEditMode() ||
        store.getState().permissionsByCompanies.listing
          .showCancelOrSaveChanges ||
        store.getState().permissionsByRepositories.listing
          .showCancelOrSaveChanges ||
        getMyProfileChanges()
      ) {
        showModal(
          'warning',
          t('documentation-confirmation-cancel-editing-title'),
          t('documentation-confirmation-cancel-editing-content'),
          () => dispatchAllLogoutOperations(),
        );
      } else return dispatchAllLogoutOperations();
    };

    const goToWebRepository = () => {
      if (!loginState.user) {
        return navigate('/login?repositoryRedirect');
      }

      showDiscardChangesModal('repositories');
    };

    const getAvatarImage = () => {
      return loginState.user?.profilePictureBase64 ? (
        <img
          alt="Avatar"
          src={`data:image/png;base64, ${loginState.user?.profilePictureBase64}`}
        />
      ) : undefined;
    };

    const getNameAndSurnameInitials = () => {
      if (loginState.user!.firstName && loginState.user!.lastName) {
        return `${loginState
          .user!.firstName.charAt(0)
          .toUpperCase()}${loginState.user!.lastName.charAt(0).toUpperCase()}`;
      }

      return '';
    };

    const getAvatarDropdownOptionsForAdmins = (userRole: 'A' | 'U') => {
      if (userRole === 'A') {
        return [
          <Menu.Item
            id="goToUsersTab"
            onClick={() =>
              showDiscardChangesModal('administrative-panel?tab=users')
            }
            key="go-to-administrative-panel-avatar-option-users"
          >
            <Row justify="space-between">
              <Col span={2} style={{ marginTop: '0.1rem' }}>
                <UsersSvg
                  style={{
                    color: '#41B4D2',
                    marginRight: '1px',
                  }}
                />
              </Col>
              <Col span={21} offset={1}>
                <span> {t('avatar-options-users')} </span>
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item
            id="goToCompaniesTab"
            onClick={() =>
              showDiscardChangesModal('administrative-panel?tab=companies')
            }
            key="go-to-administrative-panel-avatar-option-companies"
          >
            <Row justify="space-between">
              <Col span={2}>
                <CompaniesSvg
                  style={{
                    color: '#41B4D2',
                    marginRight: '1px',
                  }}
                />
              </Col>
              <Col span={21} offset={1}>
                <span> {t('avatar-options-companies')} </span>
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item
            id="goToNewsTab"
            onClick={() =>
              showDiscardChangesModal('administrative-panel?tab=news')
            }
            key="go-to-administrative-panel-avatar-option-news"
          >
            <Row justify="space-between">
              <Col span={2}>
                <NewsSvg
                  style={{
                    color: '#41B4D2',
                    marginRight: '1px',
                  }}
                />
              </Col>
              <Col span={21} offset={1}>
                <span> {t('avatar-options-news')} </span>
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item
            id="goToTermsTab"
            onClick={() =>
              showDiscardChangesModal('administrative-panel?tab=terms')
            }
            key="go-to-administrative-panel-avatar-option-terms"
          >
            <Row justify="space-between">
              <Col span={2} style={{ marginTop: '0.4rem' }}>
                <TermsSvg
                  style={{
                    color: '#41B4D2',
                    marginRight: '1px',
                  }}
                />
              </Col>
              <Col span={21} offset={1}>
                {t('avatar-options-terms')}
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item
            id="goToPermissionsTab"
            onClick={() =>
              showDiscardChangesModal('administrative-panel?tab=permissions')
            }
            key="go-to-administrative-panel-avatar-option-permissions"
          >
            <Row justify="space-between">
              <Col span={2} style={{ marginTop: '0.1rem' }}>
                <PermissionsSvg
                  style={{
                    color: '#41B4D2',
                    marginRight: '1px',
                  }}
                />
              </Col>
              <Col span={21} offset={1}>
                {t('avatar-options-permissions')}
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item
            id="goToLogsTab"
            onClick={() =>
              showDiscardChangesModal('administrative-panel?tab=logs')
            }
            key="go-to-administrative-panel-avatar-option-logs"
          >
            <Row justify="space-between">
              <Col span={2} style={{ marginTop: '0.1rem' }}>
                <LogsSvg
                  style={{
                    color: '#41B4D2',
                    marginRight: '1px',
                  }}
                />
              </Col>
              <Col span={21} offset={1}>
                {t('avatar-options-logs')}
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item
            id="goToRequestsTab"
            onClick={() =>
              showDiscardChangesModal('administrative-panel?tab=requests')
            }
            key="go-to-administrative-panel-avatar-option-requests"
          >
            <Row justify="space-between">
              <Col span={2} style={{ marginTop: '0.1rem' }}>
                <RequestsSvg
                  style={{
                    color: '#41B4D2',
                    marginRight: '1px',
                  }}
                />
              </Col>
              <Col span={21} offset={1}>
                <Fragment>
                  {t('avatar-options-requests')}
                  <PendingRequestsBadge marginLeft />
                </Fragment>
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item
            id="goToPopUpTab"
            onClick={() =>
              showDiscardChangesModal('administrative-panel?tab=pop-up')
            }
            key="go-to-administrative-panel-avatar-option-pop-up"
          >
            <Row justify="space-between">
              <Col span={2} style={{ marginTop: '0.1rem' }}>
                <MessageFilled
                  style={{
                    color: '#41B4D2',
                    marginRight: '1px',
                    fontSize: '18px',
                  }}
                />
              </Col>
              <Col span={21} offset={1}>
                {t('avatar-options-pop-up')}
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item
            id="goToMailingListTab"
            onClick={() =>
              showDiscardChangesModal('administrative-panel?tab=mailing-list')
            }
            key="go-to-administrative-panel-avatar-option-mailing-list"
          >
            <Row justify="space-between">
              <Col span={2} style={{ marginTop: '0.1rem' }}>
                <MailOutlined
                  style={{
                    color: '#41B4D2',
                    marginRight: '1px',
                    fontSize: '18px',
                  }}
                />
              </Col>
              <Col span={21} offset={1}>
                {t('avatar-options-mailing-list')}
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item
            key="avatar-divider-created-documentations-and-created-pages"
            className="special-menu-item-style"
          >
            <Row>
              <Col span={24}>
                <Divider className="divider-style" />
              </Col>
            </Row>
          </Menu.Item>,

          <Menu.Item
            id="goToCreatedPages"
            onClick={() => showDiscardChangesModal('edit-pages')}
            key="go-to-created-pages-avatar-option"
          >
            <Row justify="space-between">
              <Col span={2}>
                <BookOutlined
                  style={{
                    color: '#41B4D2',
                    marginRight: '0px',
                  }}
                />
              </Col>
              <Col span={21} offset={1}>
                <span>{t('avatar-options-go-to-documentations-created-pages')} </span>
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item
            key="avatar-divider-created-pages-and-my-profile"
            className="special-menu-item-style"
          >
            <Row>
              <Col span={24}>
                <Divider className="divider-style" />
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item
            id="goToMyProfile"
            onClick={() => showDiscardChangesModal('my-profile')}
            key="go-to-my-profile-avatar-option"
          >
            <Row justify="space-between">
              <Col span={2} style={{ marginTop: '0.1rem' }}>
                <ProfileSvg
                  style={{
                    color: '#41B4D2',
                    marginRight: '1px',
                  }}
                />
              </Col>
              <Col span={21} offset={1}>
                <span>{t('avatar-options-profile')}</span>
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item key="divider-logout" className="special-menu-item-style">
            <Row>
              <Col span={24}>
                <Divider className="divider-style" />
              </Col>
            </Row>
          </Menu.Item>,
          <Menu.Item
            id="logout"
            onClick={() => logout()}
            key="logout-avatar-option"
          >
            <Row justify="space-between">
              <Col span={2} style={{ marginTop: '0.1rem' }}>
                <LogoutSvg />
              </Col>
              <Col span={21} offset={1}>
                <span> {t('avatar-options-logout')} </span>
              </Col>
            </Row>
          </Menu.Item>,
        ];
      }

      return (
        <Menu.Item
          id="logout"
          onClick={() => logout()}
          key="logout-avatar-option"
        >
          <Row justify="space-between">
            <Col span={2} style={{ marginTop: '0.15rem' }}>
              <LogoutSvg />
            </Col>
            <Col span={20} offset={2}>
              <span> {t('avatar-options-logout')} </span>
            </Col>
          </Row>
        </Menu.Item>
      );
    };

    const showAvatarWhenLogged = () => {
      if (loginState.loggedIn) {
        return (
          <Menu.SubMenu
            key="avatar-dropdown"
            className="avatar-dropdown"
            icon={
              <PendingRequestsBadge>
                <AntdAvatar icon={getAvatarImage()} size={'default'}>
                  {loginState.user?.profilePictureBase64
                    ? undefined
                    : getNameAndSurnameInitials()}
                </AntdAvatar>
              </PendingRequestsBadge>
            }
            title={toTitleCase(loginState.user!.firstName)}
          >
            {getAvatarDropdownOptionsForAdmins(
              loginState.user!.role as 'A' | 'U',
            )}
          </Menu.SubMenu>
        );
      }

      return (
        <Menu.Item key="5" onClick={() => navigate('/login')}>
          {t('header-footer-login')}
        </Menu.Item>
      );
    };

    const showNavbarMenuOrCollapsedVersion = () => {
      if (!screens.lg) {
        return (
          <Menu
            mode="horizontal"
            theme="light"
            style={{
              width: '10%',
              marginLeft: '40%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            selectedKeys={routerState}
          >
            <Menu.SubMenu
              key="menu-dropdown-collapsed-root"
              className="menu-dropdown-collapsed-root-icon"
              icon={<MenuOutlined />}
            >
              <Menu.Item
                key={menuKeyState.pages}
                onClick={() => showDiscardChangesModal('created-pages')}
              >
                {t('header-footer-documentations')}
              </Menu.Item>
              <Menu.Item key={menuKeyState.repositories} onClick={goToWebRepository}>
                {t('header-footer-file-repository')}
              </Menu.Item>
              <Menu.Item
                key={menuKeyState.about}
                onClick={() => showDiscardChangesModal('about')}
              >
                {t('header-footer-about')}
              </Menu.Item>
              <Menu.SubMenu
                key="language-dropdown"
                className="i18n-dropdown"
                icon={getFlagIcon()}
                title={getFlagTitle()}
              >
                <Menu.Item
                  id="language-dropdown-pt-option"
                  key="language-dropdown-option-pt"
                  onClick={() => handleLanguageMenuItemClick('pt')}
                >
                  <Row>
                    <Col span={4} style={{ marginTop: '0.3rem' }}>
                      <BrazilFlag className="i18n-dropdown-flag" />
                    </Col>
                    <Col span={17} offset={3}>
                      <span>{t('header-language-dropdown-portuguese')}</span>
                    </Col>
                  </Row>
                </Menu.Item>
                <Menu.Item
                  id="language-dropdown-en-option"
                  key="language-dropdown-option-en"
                  onClick={() => handleLanguageMenuItemClick('en')}
                >
                  <Row>
                    <Col span={4} style={{ marginTop: '0.3rem' }}>
                      <USAFlag className="i18n-dropdown-flag" />
                    </Col>
                    <Col span={17} offset={3}>
                      <span>{t('header-language-dropdown-english')}</span>
                    </Col>
                  </Row>
                </Menu.Item>
                <Menu.Item
                  id="language-dropdown-es-option"
                  key="language-dropdown-option-es"
                  onClick={() => handleLanguageMenuItemClick('es')}
                >
                  <Row>
                    <Col span={4} style={{ marginTop: '0.3rem' }}>
                      <SpainFlag className="i18n-dropdown-flag" />
                    </Col>
                    <Col span={17} offset={3}>
                      <span>{t('header-language-dropdown-spanish')}</span>
                    </Col>
                  </Row>
                </Menu.Item>
              </Menu.SubMenu>
              {showAvatarWhenLogged()}
            </Menu.SubMenu>
          </Menu>
        );
      }

      return (
        <Menu
          mode="horizontal"
          theme="light"
          style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}
          selectedKeys={routerState}
        >
          <Menu.Item
            key={menuKeyState.pages}
            onClick={() => showDiscardChangesModal('created-pages')}
          >
            {t('header-footer-documentations')}
          </Menu.Item>
          <Menu.Item key={menuKeyState.repositories} onClick={goToWebRepository}>
            {t('header-footer-file-repository')}
          </Menu.Item>
          <Menu.Item key={menuKeyState.about} onClick={() => showDiscardChangesModal('about')}>
            {t('header-footer-about')}
          </Menu.Item>
          <Menu.SubMenu
            key="language-dropdown"
            className="i18n-dropdown"
            icon={getFlagIcon()}
            title={getFlagTitle()}
          >
            <Menu.Item
              id="language-dropdown-pt-option"
              key="language-dropdown-option-pt"
              onClick={() => handleLanguageMenuItemClick('pt')}
            >
              <Row>
                <Col span={4} style={{ marginTop: '0.3rem' }}>
                  <BrazilFlag className="i18n-dropdown-flag" />
                </Col>
                <Col span={17} offset={3}>
                  <span>{t('header-language-dropdown-portuguese')}</span>
                </Col>
              </Row>
            </Menu.Item>
            <Menu.Item
              id="language-dropdown-en-option"
              key="language-dropdown-option-en"
              onClick={() => handleLanguageMenuItemClick('en')}
            >
              <Row>
                <Col span={4} style={{ marginTop: '0.3rem' }}>
                  <USAFlag className="i18n-dropdown-flag" />
                </Col>
                <Col span={17} offset={3}>
                  <span>{t('header-language-dropdown-english')}</span>
                </Col>
              </Row>
            </Menu.Item>
            <Menu.Item
              id="language-dropdown-es-option"
              key="language-dropdown-option-es"
              onClick={() => handleLanguageMenuItemClick('es')}
            >
              <Row>
                <Col span={4} style={{ marginTop: '0.3rem' }}>
                  <SpainFlag className="i18n-dropdown-flag" />
                </Col>
                <Col span={17} offset={3}>
                  <span>{t('header-language-dropdown-spanish')}</span>
                </Col>
              </Row>
            </Menu.Item>
          </Menu.SubMenu>
          {showAvatarWhenLogged()}
        </Menu>
      );
    };

    const getFlagIcon = () => {
      switch (navbarState.defaultLanguage) {
        case 'pt':
          return <BrazilFlag className="i18n-flag i18n-flag-selected" />;

        case 'en':
          return <USAFlag className="i18n-flag i18n-flag-selected" />;

        case 'es':
          return <SpainFlag className="i18n-flag i18n-flag-selected" />;

        default:
          break;
      }
    };

    const getFlagTitle = () => {
      switch (navbarState.defaultLanguage) {
        case 'pt':
          return t('header-language-dropdown-portuguese');

        case 'en':
          return t('header-language-dropdown-english');

        case 'es':
          return t('header-language-dropdown-spanish');

        default:
          break;
      }
    };

    return (
      <Layout.Header className="Navbar">
        <div className="home-logo">
          <span
            className="home-logo-clickable-area"
            onClick={() => showDiscardChangesModal('home')}
          >
            <IngenicoLogo />
            <DividerSvg />
            <b className="dev-portal-label"> DevPortal </b>
          </span>
        </div>
        {showNavbarMenuOrCollapsedVersion()}
      </Layout.Header>
    );
  };

  return <Row className="Navbar">{generateHeaderColumns()}</Row>;
};
