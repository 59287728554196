import { useEffect } from 'react';

export const useScrollToTop = (id: string = 'content-row') => {
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }, 0);
  }, [id]);
};

export const scrollToTopFunction = (id: string = 'content-row') => {
  setTimeout(() => {
    const element = document.getElementById(`${id}`);
    if (element) {
      element.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, 400);
};

export const scrollToItemFunction = (item: string) => {
  setTimeout(() => {
    const element = document.querySelector(`[data-row-key="${item}"]`);
    if (element) {
      element.scrollIntoView();
    }
  }, 0);
};
