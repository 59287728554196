import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  classificationApi,
  IClassification,
} from '../../services/classification.api';
import { messageNotification } from '../../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../../helpers/unhookedTranslation/unhookedTranslation';

export interface ITagPanelInitialState {
  currentTab: string | undefined;
  isLoading: boolean;
  isCreatingNewTag: boolean;
  allTags: IClassification[] | undefined;
  enabledTags: IClassification[] | undefined;
  disabledTags: IClassification[] | undefined;
  shouldRefresh: boolean;
}

const initialState: ITagPanelInitialState = {
  currentTab: undefined,
  isLoading: false,
  isCreatingNewTag: false,
  allTags: undefined,
  enabledTags: undefined,
  disabledTags: undefined,
  shouldRefresh: true,
};

export const asyncActions = {
  GEL_ALL_TAGS_LIST: createAsyncThunk('GEL_ALL_TAGS_LIST', async () => {
    const allTagsList = await classificationApi.getAllClassifications();
    return {
      allTagsListResponse: allTagsList.data,
    };
  }),
  GET_ENABLED_TAGS_LIST: createAsyncThunk('GET_ENABLED_TAGS_LIST', async () => {
    const enabledTagsList = await classificationApi.getClassifications(true);
    return {
      enabledTagsListResponse: enabledTagsList.data,
    };
  }),
  GET_DISABLED_TAGS_LIST: createAsyncThunk(
    'GET_DISABLED_TAGS_LIST',
    async () => {
      const disabledTagsList =
        await classificationApi.getClassifications(false);
      return {
        disabledTagsListResponse: disabledTagsList.data,
      };
    },
  ),
  CREATE_NEW_TAG: createAsyncThunk('CREATE_NEW_TAG', async (payload: any) => {
    const newTagResponse =
      await classificationApi.createNewClassification(payload);
    return { createdTagResponse: newTagResponse.data };
  }),
  RENAME_TAG: createAsyncThunk('RENAME_TAG', async (payload: any) => {
    const renameTagResponse = await classificationApi.renameClassification(
      payload.id,
      {
        name: payload.newName,
      },
    );
    return { createdTagResponse: renameTagResponse.data };
  }),
  DISABLE_TAG: createAsyncThunk('DISABLE_TAG', async (id: number) => {
    await classificationApi.disableClassification(id);
  }),
  ENABLE_TAG: createAsyncThunk('ENABLE_TAG', async (id: number) => {
    await classificationApi.enableClassification(id);
  }),
  DELETE_TAG: createAsyncThunk('DELETE_TAG', async (id: number) => {
    await classificationApi.deleteClassification(id);
  }),
};

export const TagsPanelSlice = createSlice({
  name: 'tagPanelSlice',
  initialState,
  reducers: {
    TAB_CHANGE: (state, action) => {
      state.currentTab = action.payload;
    },
    BACK_TO_ORIGINAL_STATE: () => {
      return initialState;
    },
    SET_SHOULD_REFRESH: (state, action) => {
      state.shouldRefresh = action.payload;
    },
  },
  extraReducers: (builder) => [
    builder.addCase(asyncActions.GEL_ALL_TAGS_LIST.pending, (state) => {
      state.isLoading = true;
    }),
    builder.addCase(asyncActions.GEL_ALL_TAGS_LIST.rejected, (state) => {
      state.isLoading = false;
      state.allTags = undefined;
    }),
    builder.addCase(
      asyncActions.GEL_ALL_TAGS_LIST.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.allTags = action.payload.allTagsListResponse;
      },
    ),

    builder.addCase(asyncActions.GET_ENABLED_TAGS_LIST.pending, (state) => {
      state.isLoading = true;
    }),
    builder.addCase(asyncActions.GET_ENABLED_TAGS_LIST.rejected, (state) => {
      state.isLoading = false;
      state.enabledTags = undefined;
    }),
    builder.addCase(
      asyncActions.GET_ENABLED_TAGS_LIST.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.enabledTags = action.payload
          .enabledTagsListResponse as IClassification[];
      },
    ),

    builder.addCase(asyncActions.GET_DISABLED_TAGS_LIST.pending, (state) => {
      state.isLoading = true;
    }),
    builder.addCase(asyncActions.GET_DISABLED_TAGS_LIST.rejected, (state) => {
      state.isLoading = false;
      state.disabledTags = undefined;
    }),
    builder.addCase(
      asyncActions.GET_DISABLED_TAGS_LIST.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.disabledTags = action.payload
          .disabledTagsListResponse as IClassification[];
      },
    ),

    builder.addCase(asyncActions.CREATE_NEW_TAG.pending, (state) => {
      state.isCreatingNewTag = true;
    }),
    builder.addCase(asyncActions.CREATE_NEW_TAG.rejected, (state) => {
      state.isCreatingNewTag = false;
    }),
    builder.addCase(asyncActions.CREATE_NEW_TAG.fulfilled, (state) => {
      state.isCreatingNewTag = false;
      state.shouldRefresh = true;

      messageNotification.successMessage(
        unhookedTranslation(
          'tag-panel-success-notification-create-new-tag-title',
        ),
        unhookedTranslation(
          'tag-panel-success-notification-create-new-tag-content',
        ),
      );
    }),

    builder.addCase(asyncActions.RENAME_TAG.rejected, (state) => {
      state.shouldRefresh = true;
    }),
    builder.addCase(asyncActions.RENAME_TAG.fulfilled, (state) => {
      state.shouldRefresh = true;

      messageNotification.successMessage(
        unhookedTranslation('tag-panel-success-notification-edit-tag-title'),
        unhookedTranslation('tag-panel-success-notification-edit-tag-content'),
      );
    }),

    builder.addCase(asyncActions.DISABLE_TAG.rejected, (state) => {
      state.shouldRefresh = true;
      // TODO: error message
    }),
    builder.addCase(asyncActions.DISABLE_TAG.fulfilled, (state) => {
      state.shouldRefresh = true;

      messageNotification.successMessage(
        unhookedTranslation('tag-panel-success-notification-disable-tag-title'),
        unhookedTranslation(
          'tag-panel-success-notification-disable-tag-content',
        ),
      );
    }),

    builder.addCase(asyncActions.ENABLE_TAG.rejected, (state) => {
      state.shouldRefresh = true;
    }),
    builder.addCase(asyncActions.ENABLE_TAG.fulfilled, (state) => {
      state.shouldRefresh = true;

      messageNotification.successMessage(
        unhookedTranslation('tag-panel-success-notification-enable-tag-title'),
        unhookedTranslation(
          'tag-panel-success-notification-enable-tag-content',
        ),
      );
    }),

    builder.addCase(asyncActions.DELETE_TAG.rejected, (state) => {
      state.shouldRefresh = true;

      messageNotification.errorMessage(
        unhookedTranslation('tag-panel-error-notification-delete-tag-title'),
        unhookedTranslation('tag-panel-error-notification-delete-tag-content'),
      );
    }),
    builder.addCase(asyncActions.DELETE_TAG.fulfilled, (state) => {
      state.shouldRefresh = true;

      messageNotification.successMessage(
        unhookedTranslation('tag-panel-success-notification-delete-tag-title'),
        unhookedTranslation(
          'tag-panel-success-notification-delete-tag-content',
        ),
      );
    }),
  ],
});
