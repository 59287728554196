import { Form, Input, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

export interface RepositoryCreateEditModalValues {
  name: string;
  description: string;
}

export interface IRepositoryCreateEditModalProps {
  open: boolean;
  allowNameEdit: boolean;
  title: string;
  okText: string;
  cancelText: string;
  nameText: string;
  descriptionText: string;
  confirmLoading: boolean;
  currentName?: string;
  currentDescription?: string;
  onOk: (values: RepositoryCreateEditModalValues) => void;
  onCancel: () => void;
}

export const RepositoryCreateEditModal: React.FC<
  IRepositoryCreateEditModalProps
> = ({
  open,
  allowNameEdit,
  title,
  okText,
  cancelText,
  nameText,
  descriptionText,
  confirmLoading,
  currentName,
  currentDescription,
  onOk,
  onCancel,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      centered
      open={open}
      title={title}
      okText={okText}
      cancelText={cancelText}
      confirmLoading={confirmLoading}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form.validateFields().then((values) => {
          form.resetFields();
          onOk(values);
        });
      }}
    >
      <Form form={form} layout="vertical" name="repositoryCreateModalForm">
        {allowNameEdit && (
          <Form.Item
            name="name"
            label={nameText}
            rules={[{ required: true, message: 'Obrigatório' }]}
            initialValue={currentName}
          >
            <Input maxLength={50} />
          </Form.Item>
        )}
        <Form.Item name="description" label={descriptionText}>
          <TextArea
            showCount
            rows={3}
            defaultValue={currentDescription}
            maxLength={250}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
