import React, { useEffect } from 'react';
import { Row, Col, Tabs } from 'antd';

import './Terms.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '../../../../store/store';
import { getEditorToPreview } from '../../../../helpers/getEditorToPreview/getEditorToPreview';

import {
  RequestAccessTermsAsyncActions,
  RequestAccessTermsSlice,
} from './Terms.Slice';

export const Terms = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const { TabPane } = Tabs;

  const termContent = useSelector(
    (state: RootState) => state.requestAccessTerms.content,
  );

  const termsLanguages = useSelector(
    (state: RootState) => state.requestAccessTerms.language.selectedLanguage,
  );

  const languageInNavbar = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );

  useEffect(() => {
    dispatch(RequestAccessTermsAsyncActions.GET_TERM());
    dispatch(
      RequestAccessTermsSlice.actions.SET_SELECTED_LANGUAGE(languageInNavbar),
    );

    return () => {
      dispatch(RequestAccessTermsSlice.actions.BACK_TO_ORIGINAL_STATE());
    };
  }, [dispatch, languageInNavbar]);

  const getEditorDefaultValue = (): string => {
    switch (termsLanguages) {
      case 'pt':
        return termContent.ptContent;

      case 'es':
        return termContent.esContent;

      case 'en':
        return termContent.enContent;

      default:
        return '';
    }
  };

  return (
    <Row className="Terms">
      <Col span={24}>
        <Row>
          <Col className="terms-title-row" span={24} id="terms-id">
            <p className="terms-title"> {t('request-access-terms-title')} </p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs
              activeKey={termsLanguages}
              onChange={(activeKey) => {
                dispatch(
                  RequestAccessTermsSlice.actions.SET_SELECTED_LANGUAGE(
                    activeKey,
                  ),
                );
              }}
              centered
            >
              <TabPane tab={t('request-access-terms-pt-tab')} key="pt">
                {getEditorToPreview(getEditorDefaultValue())}
              </TabPane>
              <TabPane tab={t('request-access-terms-en-tab')} key="en">
                {getEditorToPreview(getEditorDefaultValue())}
              </TabPane>
              <TabPane tab={t('request-access-terms-es-tab')} key="es">
                {getEditorToPreview(getEditorDefaultValue())}
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
