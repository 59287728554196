import { Modal, Progress } from 'antd';
import React from 'react';

export interface IRepositoryDownloadModalProps {
  open: boolean;
  title: string;
  fileName: string;
  okText: string;
  cancelText: string;
  percent: number;
  loading: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const RepositoryDownloadModal: React.FC<
  IRepositoryDownloadModalProps
> = ({
  open,
  title,
  fileName,
  okText,
  cancelText,
  percent,
  loading,
  onOk,
  onCancel,
}) => {
  return (
    <Modal
      centered
      open={open}
      title={`${title} ${fileName}`}
      okText={okText}
      cancelText={cancelText}
      confirmLoading={loading}
      onOk={() => {
        onOk();
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Progress percent={percent} />
    </Modal>
  );
};
