import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Form, Input, Popconfirm, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDown } from '../../../assets/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../assets/arrow-up.svg';
import { ReactComponent as EditPencil } from '../../../assets/popover-icons/edit.svg';
import './TableWithEditAndExpansion.scss';
import { IClassification } from '../../../services/classification.api';
import { showModal } from '../../../helpers/showModal/showModal';

interface ITagsTable {
  key: string;
  name: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: ITagsTable;
  index: number;
  children: React.ReactNode;
}

interface ITableWithEditAndExpansion {
  dataSource: IClassification[] | undefined;
  isDisabledTable: boolean;
  numberOfItems: number | undefined;
  handleEditTag: Function;
  showDisableOrDeleteModalDisable: Function;
  showDisableConfirmationModal: Function;
  showEnableConfirmationModal: Function;
}

export const TableWithEditAndExpansion: React.FC<
  ITableWithEditAndExpansion
> = ({
  dataSource,
  isDisabledTable,
  handleEditTag,
  showDisableOrDeleteModalDisable,
  showEnableConfirmationModal,
}: ITableWithEditAndExpansion) => {
  const [editingKey, setEditingKey] = useState('');
  const [expanding, setExpanding] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<number[]>([]);

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const isEditing = (record: any) => record.key === editingKey;

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedKeys([...expandedKeys, record.key]);
    } else {
      setExpandedKeys(expandedKeys.filter((key) => key !== record.key));
    }
  };

  const edit = (record: any) => {
    form.setFieldsValue({
      name: record.name,
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record: any) => {
    const formValues = await form.validateFields();
    const newName = formValues.name.trim();
    if (newName < 2 || newName > 30) {
      showModal(
        'info',
        t('tag-panel-edit-tag-error-modal-title'),
        t('tag-panel-edit-tag-error-modal-content'),
        () => {},
        () => {},
        t('tag-panel-edit-tag-close-modal-label'),
        '',
        undefined,
      );
      return;
    }

    setEditingKey('');
    handleEditTag(record.id, newName);
  };

  let columns = [
    {
      title: t('tag-panel-tag-name-column-title'),
      dataIndex: 'name',
      editable: true,
      width: '85%',
    },
  ];

  const renderEditActions = (record: any) => {
    return (
      <span className="table-operations-buttons-container">
        <Typography.Link
          onClick={() => save(record)}
          style={{ marginRight: 8 }}
        >
          {t('tag-panel-edit-tag-save-button-label')}
        </Typography.Link>
        <Popconfirm
          title={t('tag-panel-edit-tag-cancel-button-sure-cancel-label')}
          cancelText={t('tag-panel-edit-tag-cancel-button-label')}
          onConfirm={cancel}
        >
          <a>{t('tag-panel-edit-tag-cancel-button-label')}</a>
        </Popconfirm>
      </span>
    );
  };

  const renderButtonsActions = (record: any) => {
    return (
      <div className="table-operations-buttons-container">
        <Button
          disabled={editingKey !== ''}
          onClick={() => edit(record)}
          className="table-operations-buttons"
          title="Edit"
        >
          <EditPencil />
          {t('tag-panel-tags-table-column-edit-option')}
        </Button>
        <Button
          disabled={editingKey !== ''}
          onClick={() => showDisableOrDeleteModalDisable(record)}
          className="table-operations-buttons"
          title={t('tag-panel-tags-table-column-disable-option')}
        >
          <StopOutlined style={{ color: '#41B4D2', marginLeft: 8 }} />
          {t('tag-panel-tags-table-column-disable-option')}
        </Button>
      </div>
    );
  };

  if (!isDisabledTable) {
    columns.push({
      title: '',
      dataIndex: '',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        const isInnerChild = !record.children;
        if (isInnerChild) {
          return editable
            ? renderEditActions(record)
            : renderButtonsActions(record);
        }
      },
    } as any);
  }

  if (isDisabledTable) {
    columns.push({
      title: '',
      dataIndex: '',
      render: (_: any, record: any) => {
        const isInnerChild = !record.children;
        if (isInnerChild) {
          return (
            <div className="table-operations-buttons-container">
              <Button
                disabled={editingKey !== ''}
                onClick={() => showEnableConfirmationModal(record.id)}
                className="table-operations-buttons"
                title={t('tag-panel-tags-table-column-enable-option')}
              >
                <CheckOutlined style={{ color: '#41B4D2', marginLeft: 8 }} />
                {t('tag-panel-tags-table-column-enable-option')}
              </Button>
            </div>
          );
        }
      },
    } as any);
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: <Input />,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const editableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const toggleExpansion = () => {
    setExpanding(!expanding);
  };

  return (
    <Form form={form} component={false} className="TagPanelTable">
      <Table
        components={{
          body: {
            cell: editableCell,
          },
        }}
        dataSource={expanding ? dataSource : dataSource?.slice(0, 2)}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        onExpand={handleExpand}
        footer={() => {
          const footerLabel = expanding
            ? t('administrative-panel-requests-table-col-see-less')
            : t('administrative-panel-requests-table-col-see-more');
          const footerIcon = expanding ? <ArrowUp /> : <ArrowDown />;

          return (
            <div className="table-footer-button-container">
              <Button
                onClick={() => toggleExpansion()}
                className="table-footer-button"
              >
                {footerLabel} {footerIcon}
              </Button>
            </div>
          );
        }}
      />
    </Form>
  );
};
