import { Button, Modal, Progress } from 'antd';
import React, { useEffect, useState } from 'react';

import './RepositoryViewTxtModal.scss';

interface IModalState {
  content: string | ArrayBuffer | null;
}

export interface IRepositoryViewTxtModalProps {
  open: boolean;
  title: string;
  fileName: string;
  downloadText: string;
  closeText: string;
  cancelText: string;
  progress: number;
  loading: boolean;
  onOk: () => void;
  onCancel: () => void;
  fileData: string;
}

export const RepositoryViewTxtModal: React.FC<IRepositoryViewTxtModalProps> = ({
  open,
  title,
  fileName,
  downloadText,
  closeText,
  cancelText,
  progress,
  loading,
  onOk,
  onCancel,
  fileData,
}) => {
  const [modalState, setModalState] = useState<IModalState>();

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => {
      setModalState({ content: reader.result });
    };
    reader.readAsText(
      new Blob([fileData], { type: 'text/plain;charset=utf-8' }),
    );
  }, [fileData]);

  return (
    <Modal
      centered
      bodyStyle={{ height: '80vh', overflowY: 'auto' }}
      width={'50vw'}
      open={open}
      title={`${title} ${fileName}`}
      confirmLoading={loading}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {loading ? cancelText : closeText}
        </Button>,
        <Button key="download" type="primary" loading={loading} onClick={onOk}>
          {downloadText}
        </Button>,
      ]}
    >
      {loading && (
        <div className="progress-container">
          <Progress type="circle" percent={progress} />
        </div>
      )}

      {!loading && (
        <pre className="pre-wrap">{modalState?.content as string}</pre>
      )}
    </Modal>
  );
};
