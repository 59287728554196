import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

// Define the version of the translation files
const translationVersion = '1.0.0'; // Update this version manually whenever the translation files are updated

const browserLanguage = navigator.language;
let languageCheck = 'en';

if (browserLanguage.includes('pt')) {
  languageCheck = 'pt';
} else if (browserLanguage.includes('en')) {
  languageCheck = 'en';
} else if (browserLanguage.includes('es')) {
  languageCheck = 'es';
}

const defaultLanguage = languageCheck;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: defaultLanguage,
    fallbackLng: 'en',
    supportedLngs: ['en', 'pt', 'es'],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${translationVersion}`,
    },
  });

// eslint-disable-next-line import/no-default-export
export default i18n;
export { defaultLanguage };
