import React, { useEffect, Fragment, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Space,
  Card,
  Input,
  Skeleton,
  Result,
  Spin,
  TreeSelect,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FolderOpenOutlined } from '@ant-design/icons';
import { Header } from '../../components/Header/Header';
import { AppDispatch, RootState } from '../../store/store';
import { PageSearch } from '../../components/PageSearch/PageSearch';
import { ReactComponent as ReturnArrow } from '../../assets/return_arrow_icon.svg';
import { PageResult } from '../../components/PageSearch/PageResult/PageResult';

import './PagesList.scss';
import {
  asyncPageActions,
  CreateReadEditPageSlice,
  ITagTreeNode,
} from './CreateReadEditPages/CreateReadEditPage.Slice';

import { PathTrackerSlice } from '../PathTracker/PathTracker.Slice';
import { asyncActions } from '../TagsPanel/TagsPanel.Slice';
import { PageCard } from './PageCard/PageCard';

// This conversion is necessary because the backend expects the full name of the language in uppercase, while the navbar stores the current language as an abbreviation.
const languageMap: { [key: string]: string } = {
  en: 'ENGLISH',
  es: 'SPANISH',
  pt: 'PORTUGUESE',
};

interface IPageContentCard {
  id: number;
  subtitle: string;
  title: string;
  language: string;
  description: string;
}

export interface IPageCardItem {
  id: number;
  classificationIds: number[];
  active: boolean;
  createdAt: string;
  createdBy: string;
  pageContents: IPageContentCard[];
  slug: string;
  pages: [];
  name: string;
}

interface IMappedAllTagsIds {
  id: number;
  value: string;
  children: IMappedAllTagsIds[] | undefined;
}

export const PagesList = () => {
  const [tagTreeData, setTagTreeData] = useState<ITagTreeNode[]>([]);
  const [allTagsIdsList, setAllTagsIdsList] = useState<IMappedAllTagsIds[]>();
  const [selectIdsToFilter, setSelectIdsToFilter] = useState<
    number[] | string[]
  >([]);
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const loginState = useSelector((state: RootState) => state.login);
  const createReadEditPageState = useSelector(
    (state: RootState) => state.createReadEditPageSlice,
  );

  const loadedPagesState = useSelector(
    (state: RootState) => state.createReadEditPageSlice.loadedPages,
  );
  const navbarState = useSelector((state: RootState) => state.navBar);

  const allTagsState = useSelector(
    (state: RootState) => state.tagPanel.allTags,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const { Meta } = Card;
  const { Search } = Input;

  // The same as the one being validated on the home page
  useEffect(() => {
    const storedOnStartMessage = localStorage.getItem('on-start-message');

    const defaultLanguage = languageMap[navbarState.defaultLanguage];

    if (
      !window.location.href.includes('created-pages?networkRedirector=true')
    ) {
      dispatch(asyncPageActions.GET_ALL_PAGES(defaultLanguage));
    } else if (
      window.location.href.includes('created-pages?networkRedirector=true') &&
      !storedOnStartMessage
    ) {
      dispatch(asyncPageActions.GET_ALL_PAGES(defaultLanguage));
    }

    return () => {
      dispatch(CreateReadEditPageSlice.actions.BACK_TO_ORIGINAL_STATE());
    };
  }, [dispatch, navbarState.defaultLanguage]);

  useEffect(() => {
    if (
      loadedPagesState &&
      window.location.href.includes('created-pages?networkRedirector=true')
    ) {
      navigate('/created-pages');
    }
  }, [loadedPagesState, history, dispatch]);

  useEffect(() => {
    dispatch(asyncActions.GEL_ALL_TAGS_LIST());
  }, []);

  useEffect(() => {
    if (allTagsState) {
      const mappedTagListTree = allTagsState.map((tag) => ({
        title: tag.name || '',
        value: tag.id?.toString(),
        children: tag.subClassifications?.map((child: any) => ({
          title: child.name,
          value: child.id.toString(),
          children: child.subClassifications.map((item: any) => ({
            title: item.name,
            value: item.id.toString(),
          })),
        })),
      }));

      setTagTreeData(mappedTagListTree);

      const mappedAllTagsIds = allTagsState.map((tag) => ({
        id: tag.id,
        value: tag.name || '',
        children:
          tag?.subClassifications &&
          tag?.subClassifications.map((child: any) => ({
            id: child.id,
            value: child.name,
            children: child.subClassifications.map((item: any) => ({
              id: item.id,
              value: item.name,
              children: item.subClassifications,
            })),
          })),
      }));
      setAllTagsIdsList(mappedAllTagsIds);
    }
  }, [allTagsState]);

  const goToPageCreator = () => {
    navigate('/pages', { state: { mode: 'create' } });
  };
  const setTitleToRemakeSearchPage = (value: string) => {
    dispatch(
      asyncPageActions.FIND_PAGE_BY_TITLE({
        language: languageMap[navbarState.defaultLanguage],
        title: value,
      }),
    );
  };
  const goToSpecificPage = (slug: string) => {
    dispatch(
      PathTrackerSlice.actions.SET_PREVIOUS_PATH_FOR_PAGES('/created-pages'),
    );
    navigate(`/pages/${slug}`);
  };

  const generateGroupedByClassifications = (pagesListContent: any) => {
    const groupedByClassifications = pagesListContent
      ? pagesListContent.reduce((acc: any, item: any) => {
          item.classificationIds.forEach((classificationId: never) => {
            const levelOne =
              allTagsIdsList &&
              allTagsIdsList.find(
                (levelOneItem: any) => levelOneItem.id === classificationId,
              );

            if (levelOne && selectIdsToFilter?.includes(classificationId)) {
              let existingClassification = acc.find(
                (c: any) => c.id === classificationId,
              );

              if (!existingClassification) {
                existingClassification = {
                  id: classificationId,
                  name: levelOne.value,
                  pages: [],
                  children: [],
                };
                acc.push(existingClassification);
              }

              if (
                !existingClassification.pages.some(
                  (page: any) => page.id === item.id,
                )
              ) {
                existingClassification.pages.push(item);
              }
            } else {
              allTagsIdsList?.forEach((levelOneItem: any) => {
                const levelTwo = levelOneItem.children.find(
                  (child: any) => child.id === classificationId,
                );

                if (levelTwo && selectIdsToFilter?.includes(classificationId)) {
                  let existingLevelOne = acc.find(
                    (c: any) => c.id === levelOneItem.id,
                  );

                  if (!existingLevelOne) {
                    existingLevelOne = {
                      id: levelOneItem.id,
                      name: levelOneItem.value,
                      pages: [],
                      children: [],
                    };
                    acc.push(existingLevelOne);
                  }

                  let existingLevelTwo = existingLevelOne.children.find(
                    (child: any) => child.id === classificationId,
                  );

                  if (!existingLevelTwo) {
                    existingLevelTwo = {
                      id: classificationId,
                      name: levelTwo.value,
                      pages: [],
                      children: [],
                    };
                    existingLevelOne.children.push(existingLevelTwo);
                  }

                  if (
                    !existingLevelTwo.pages.some(
                      (page: any) => page.id === item.id,
                    )
                  ) {
                    existingLevelTwo.pages.push(item);
                  }
                } else {
                  levelOneItem.children.forEach((levelTwoItem: any) => {
                    const levelThree = levelTwoItem.children.find(
                      (child: any) => child.id === classificationId,
                    );

                    if (
                      levelThree &&
                      selectIdsToFilter?.includes(classificationId)
                    ) {
                      let existingLevelOne = acc.find(
                        (c: any) => c.id === levelOneItem.id,
                      );

                      if (!existingLevelOne) {
                        existingLevelOne = {
                          id: levelOneItem.id,
                          name: levelOneItem.value,
                          pages: [],
                          children: [],
                        };
                        acc.push(existingLevelOne);
                      }

                      let existingLevelTwo = existingLevelOne.children.find(
                        (child: any) => child.id === levelTwoItem.id,
                      );

                      if (!existingLevelTwo) {
                        existingLevelTwo = {
                          id: levelTwoItem.id,
                          name: levelTwoItem.value,
                          pages: [],
                          children: [],
                        };
                        existingLevelOne.children.push(existingLevelTwo);
                      }

                      let existingLevelThree = existingLevelTwo.children.find(
                        (child: any) => child.id === classificationId,
                      );

                      if (!existingLevelThree) {
                        existingLevelThree = {
                          id: classificationId,
                          name: levelThree.value,
                          pages: [],
                          children: [],
                        };
                        existingLevelTwo.children.push(existingLevelThree);
                      }

                      if (
                        !existingLevelThree.pages.some(
                          (page: any) => page.id === item.id,
                        )
                      ) {
                        existingLevelThree.pages.push(item);
                      }
                    }
                  });
                }
              });
            }
          });
          return acc;
        }, [])
      : undefined;

    return groupedByClassifications;
  };

  const generatePagesCardListing = () => {
    const pagesListContent = createReadEditPageState.resultListResponseFilter;
    const groupedByClassifications =
      generateGroupedByClassifications(pagesListContent);

    if (!createReadEditPageState.pageListResponse?.content) {
      return (
        <Row className="no-pages-existing" align="middle" justify="center">
          <Col span={24}>
            <Spin
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '200px',
              }}
              size="large"
            />
          </Col>
        </Row>
      );
    }

    if (
      !isFiltering &&
      createReadEditPageState.pageListResponse.content.length === 0 &&
      selectIdsToFilter?.length === 0
    ) {
      return (
        <Row className="no-pages-existing" align="middle" justify="center">
          <Col span={24}>
            <Result
              icon={<FolderOpenOutlined />}
              status="404"
              title={t('documentations-listing-length-equals-to-zero-title')}
              subTitle={t(
                'documentations-listing-length-equals-to-zero-content',
              )}
            />
          </Col>
        </Row>
      );
    }

    if (
      isFiltering &&
      groupedByClassifications &&
      groupedByClassifications.length > 0
    ) {
      return (
        <>
          <Row>
            <Col>
              <h1>{t('home-page-digital-solution')}</h1>
            </Col>
          </Row>
          <Row justify="center" gutter={8} className="classification-item">
            {groupedByClassifications.map((levelOne: any) => {
              return (
                <>
                  <Col span={20}>
                    <h2 className="classification-level-one-title">
                      {levelOne.name}
                    </h2>
                  </Col>
                  {levelOne.children.length === 0
                    ? levelOne.pages.map((levelOnePage: IPageCardItem) => {
                        return (
                          <PageCard
                            page={levelOnePage}
                            goToSpecificPage={goToSpecificPage}
                            key={levelOne.id}
                          />
                        );
                      })
                    : levelOne.children.map((levelTwo: any) => {
                        return (
                          <>
                            <Col span={20}>
                              <h3 className="classification-level-two-title">
                                {levelTwo.name}
                              </h3>
                            </Col>
                            {levelTwo.children.length === 0
                              ? levelTwo.pages.map(
                                  (levelTwoPage: IPageCardItem) => {
                                    return (
                                      <PageCard
                                        page={levelTwoPage}
                                        goToSpecificPage={goToSpecificPage}
                                        key={levelTwoPage.id}
                                      />
                                    );
                                  },
                                )
                              : levelTwo.children.map(
                                  (levelThree: IPageCardItem) => {
                                    return (
                                      <>
                                        <Col span={20}>
                                          <h4 className="classification-level-three-title">
                                            {levelThree.name}
                                          </h4>
                                        </Col>
                                        {levelThree.pages.map(
                                          (levelThreePage: IPageCardItem) => {
                                            return (
                                              <PageCard
                                                page={levelThreePage}
                                                goToSpecificPage={
                                                  goToSpecificPage
                                                }
                                                key={levelThreePage.id}
                                              />
                                            );
                                          },
                                        )}
                                      </>
                                    );
                                  },
                                )}
                          </>
                        );
                      })}
                </>
              );
            })}
          </Row>
        </>
      );
    }

    if (
      isFiltering &&
      groupedByClassifications?.length === 0 &&
      selectIdsToFilter?.length !== 0
    ) {
      return (
        <Row className="no-pages-existing" align="middle" justify="center">
          <Col span={24}>
            <Result
              status="404"
              title={t(
                'page-listing-result-filter-length-equals-to-zero-title',
              )}
              subTitle={t(
                'page-listing-result-filter-length-equals-to-zero-subtitle',
              )}
            />
          </Col>
        </Row>
      );
    }

    return (
      <>
        <Row>
          <Col>
            <h1>{t('home-page-digital-solution')}</h1>
          </Col>
        </Row>
        <Row justify="center" gutter={8} className="pages-card-listing">
          {createReadEditPageState.pageListResponse?.content
            .filter((x) => x.mainContent)
            .map((x) => {
              return (
                <Col
                  className="page-card-item"
                  span={10}
                  key={x.page.id}
                  id="card-item"
                >
                  <Card onClick={() => goToSpecificPage(x.page.slug)} hoverable>
                    <Meta
                      title={x.title.toUpperCase()}
                      description={x.subtitle}
                    />
                  </Card>
                </Col>
              );
            })}
        </Row>
      </>
    );
  };

  const onChangeSelectTree = (selectsIds: any) => {
    setSelectIdsToFilter(selectsIds);
  };

  const showButtonsIfLoggedIn = () => {
    if (!loginState.loggedIn) {
      return;
    }

    if (loginState.user?.role === 'U') {
      return;
    }

    return (
      <Col span={22} className="page-buttons-col">
        <Space>
          <Button
            name="created-pages"
            type="primary"
            shape="round"
            ghost
            onClick={() => navigate('/edit-pages')}
          >
            {t('pages-created-documentations-title')}
          </Button>
          <Button
            name="new-page"
            type="primary"
            shape="round"
            ghost
            onClick={goToPageCreator}
          >
            {t('pages-created-create-a-new-documentation')}
          </Button>
          <Button
            name="manage-tags"
            type="primary"
            shape="round"
            ghost
            onClick={() => navigate('/tags-panel')}
          >
            {t('page-tags-manage-tags')}
          </Button>
        </Space>
      </Col>
    );
  };

  const submitFilterTags = () => {
    if (selectIdsToFilter?.length === 0) {
      dispatch(
        asyncPageActions.GET_ALL_PAGES(
          languageMap[navbarState.defaultLanguage],
        ),
      );
      setIsFiltering(false);
      return;
    }
    setIsFiltering(true);

    const convertIdToNumber = selectIdsToFilter?.map((id) => Number(id));
    setSelectIdsToFilter(convertIdToNumber);

    let queryString = '';
    if (selectIdsToFilter && selectIdsToFilter.length < 2) {
      queryString = `classificationIds=${selectIdsToFilter}`;
    } else if (selectIdsToFilter && selectIdsToFilter.length >= 2) {
      for (let id of selectIdsToFilter) {
        queryString += `classificationIds=${id}&`;
      }
    }

    dispatch(asyncPageActions.GET_PAGE_BY_CLASSIFICATIONS_ID(queryString));
    generatePagesCardListing();
  };

  const handleClear = () => {
    setSelectIdsToFilter([]);
    setIsFiltering(false);
    dispatch(CreateReadEditPageSlice.actions.CLEAR_TREE_FILTER());
    dispatch(
      asyncPageActions.GET_ALL_PAGES(languageMap[navbarState.defaultLanguage]),
    );
  };

  const showListPagesOrResultSearch = () => {
    if (window.location.href.includes('created-pages?networkRedirector=true')) {
      return (
        <Row className="no-pages-existing" align="middle" justify="center">
          <Col span={24}>
            <Result
              icon={<FolderOpenOutlined />}
              status="404"
              title={t('return-message-network-error-documentations-title')}
              subTitle={t(
                'return-message-network-error-documentations-description',
              )}
            />
          </Col>
        </Row>
      );
    }

    if (createReadEditPageState.showAllPages === true) {
      return (
        <>
          <Row className="page-listing-title-search" align="middle">
            <Col flex="auto">
              <Space>
                <TreeSelect
                  showSearch
                  style={{ width: '350px' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  allowClear
                  value={
                    selectIdsToFilter && selectIdsToFilter.length > 0
                      ? selectIdsToFilter
                      : undefined
                  }
                  placeholder={t('page-tags-filter-select-placeholder')}
                  multiple
                  treeDefaultExpandAll
                  onChange={onChangeSelectTree}
                  treeData={tagTreeData}
                />
                <Button
                  name="submit-filter-tag"
                  type="primary"
                  shape="round"
                  ghost
                  onClick={() => submitFilterTags()}
                >
                  {t('page-tags-filter-submit-filter')}
                </Button>
                <Button
                  name="clear-filter-tag"
                  type="primary"
                  shape="round"
                  ghost
                  onClick={handleClear}
                >
                  {t('page-tags-filter-clear-filter')}
                </Button>
              </Space>
            </Col>
            <Col className="search-page-field">
              <PageSearch />
            </Col>
          </Row>
          <Row>
            <Col span={24}>{generatePagesCardListing()}</Col>
          </Row>
        </>
      );
    }

    if (createReadEditPageState.loadingSearchedPage) {
      return <Skeleton active />;
    }

    return showResultSearchList();
  };

  const showNumberOfTotalResults = () => {
    if (
      createReadEditPageState.pageObjectResponseSearch?.numberOfElements === 0
    ) {
      return `${t('documentation-total-zero-results')}`;
    }

    if (
      createReadEditPageState.pageObjectResponseSearch?.numberOfElements === 1
    ) {
      return `${
        createReadEditPageState.pageObjectResponseSearch?.numberOfElements
      }
      ${t('documentation-total-one-results')}`;
    }
    return `${
      createReadEditPageState.pageObjectResponseSearch?.numberOfElements
    } ${t('documentation-total-multiple-results')}`;
  };

  const showResultSearchList = () => {
    return (
      <Fragment>
        <Row className="listing-result" justify="space-between">
          <Col id="return-button">
            <ReturnArrow
              className="return-button"
              onClick={() =>
                dispatch(
                  CreateReadEditPageSlice.actions.BACK_TO_ALL_PAGES_LISTING(),
                )
              }
            />
          </Col>
          <Col span={6} pull={7} id="search-page-result-list">
            <Search
              className="search-button-results"
              name="search-button-results"
              placeholder={t('documentation-listing-search')}
              defaultValue={createReadEditPageState.searchPageTitle}
              onSearch={(x) => setTitleToRemakeSearchPage(x)}
            />
          </Col>
          <Col className="total-results">{showNumberOfTotalResults()}</Col>
        </Row>
        <Row className="page-card-results">
          <PageResult
            focusedWord={createReadEditPageState.searchPageTitle}
            goToBeadcrumb={goToSpecificPage}
            searchResult={createReadEditPageState.pageObjectResponseSearch}
          />
        </Row>
      </Fragment>
    );
  };

  return (
    <Col pull={1} span={22} push={1} id="pages-list-id">
      <div>
        <Header
          title={t('header-footer-documentations')}
          subtitle={t('pages-created-documentations-subtitle')}
          showEllipsis
        >
          <Row>{showButtonsIfLoggedIn()}</Row>
        </Header>
        {showListPagesOrResultSearch()}
      </div>
    </Col>
  );
};
