import { Card, Col } from 'antd';
import React from 'react';
import { IPageCardItem } from '../PagesList';

interface IPageCard {
  page: IPageCardItem;
  goToSpecificPage: Function;
}
export const PageCard = ({ page, goToSpecificPage }: IPageCard) => {
  const { Meta } = Card;

  return (
    <Col className="page-card-item" span={10} key={page.id} id="card-item">
      <Card onClick={() => goToSpecificPage(page.slug)} hoverable>
        <Meta
          title={page.pageContents[0]?.title.toUpperCase()}
          description={page.pageContents[0]?.description}
        />
      </Card>
    </Col>
  );
};
