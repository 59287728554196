import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { messageNotification } from '../helpers/messageNotification/messageNotification';
import { unhookedTranslation } from '../helpers/unhookedTranslation/unhookedTranslation';
import { IErrorResponseData } from '../interfaces/IErrorResponseData';

const getAPIPath = () => {
  return process.env.NODE_ENV === 'production'
    ? '/api/v3/'
    : 'https://devportal-development.venturus.org.br/api/v3/';
};

const commonConfig = {
  baseURL: getAPIPath(),
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
};

const multiPartConfig = {
  baseURL: getAPIPath(),
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  timeout: 15000,
};

const nonSecuredBaseApi: AxiosInstance = axios.create({
  withCredentials: false,
  ...commonConfig,
} as AxiosRequestConfig);

const nonSecuredMultipartApi: AxiosInstance = axios.create({
  withCredentials: false,
  ...multiPartConfig,
});

const securedBaseApi: AxiosInstance = axios.create({
  withCredentials: true,
  ...commonConfig,
} as AxiosRequestConfig);

const securedMultipartApi: AxiosInstance = axios.create({
  withCredentials: true,
  ...multiPartConfig,
});

const softSecuredBaseApi: AxiosInstance = axios.create({
  withCredentials: true,
  ...commonConfig,
} as AxiosRequestConfig);

const showErrorMessageFromBackendValidation = (i18nkey: string | undefined) => {
  const supressErrorNotifications = [
    'erro-request-access-company-not-found',
    'error-company-with-refused-solicitation',
    'error-company-with-pending-solicitation',
    'error-user-with-pending-solicitation',
    'error-user-with-refused-solicitation',
    'error-confirmation-token-invalid-or-already-used',
    'error-company-solicitation-pending',
  ];

  if (!i18nkey) {
    return;
  }

  if (supressErrorNotifications.includes(i18nkey)) {
    return;
  }

  messageNotification.errorMessage(
    unhookedTranslation(`${i18nkey}-title`),
    unhookedTranslation(`${i18nkey}-content`),
  );
};

const handleServerErrorsAndUnauthorizedRequests = (error: AxiosError) => {
  const locationPath = window.location.pathname;
  if (
    error.message.includes('Network Error') ||
    error.response?.status === 503 ||
    error.message.includes('Service Unavailable')
  ) {
    localStorage.setItem('on-start-message', 'network-error');

    if (
      locationPath.includes('home') ||
      locationPath.includes('created-pages')
    ) {
      window.location.href = `${locationPath}?networkRedirector=true`;
    } else if (locationPath.includes('about')) {
      return;
    } else window.location.href = '/login';
  }

  if (error.code === '403' || error.message.includes('403')) {
    localStorage.setItem('on-start-message', 'forbidden');
    window.location.href = '/home';
  }

  if (error.code === '401' || error.message.includes('401')) {
    if (!error?.config?.url?.includes('login')) {
      localStorage.setItem('on-start-message', 're-authenticate');
      window.location.href = '/login';
    }
  }

  if (error.response?.data) {
    const data = error.response.data as IErrorResponseData;
    if (data.i18n) {
      showErrorMessageFromBackendValidation(data.i18n);
      error.message = data.i18n;
    }
  }

  return Promise.reject(error);
};

securedBaseApi.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (!token) {
    localStorage.setItem('on-start-message', 're-authenticate');
    window.location.href = '/home';
  }

  config.headers.Authorization = `${token}`;
  return config;
});

securedMultipartApi.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (!token) {
    localStorage.setItem('on-start-message', 're-authenticate');
    window.location.href = '/home';
  }

  config.headers.Authorization = `${token}`;
  return config;
});

softSecuredBaseApi.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `${token}`;
  }

  return config;
});

softSecuredBaseApi.interceptors.response.use(undefined, (error: AxiosError) => {
  const locationPath = window.location.pathname;
  if (
    error.message.includes('Network Error') ||
    error.response?.status === 503 ||
    error.message.includes('Service Unavailable')
  ) {
    localStorage.setItem('on-start-message', 'network-error');
    if (
      locationPath.includes('home') ||
      locationPath.includes('created-pages')
    ) {
      window.location.href = `${locationPath}?networkRedirector=true`;
    }
  }
});

nonSecuredBaseApi.interceptors.response.use(undefined, (error: AxiosError) => {
  return handleServerErrorsAndUnauthorizedRequests(error);
});

nonSecuredMultipartApi.interceptors.response.use(
  undefined,
  (error: AxiosError) => {
    return handleServerErrorsAndUnauthorizedRequests(error);
  },
);

securedMultipartApi.interceptors.response.use(
  undefined,
  (error: AxiosError) => {
    return handleServerErrorsAndUnauthorizedRequests(error);
  },
);

securedBaseApi.interceptors.response.use(undefined, (error: AxiosError) => {
  return handleServerErrorsAndUnauthorizedRequests(error);
});

export {
  nonSecuredBaseApi,
  nonSecuredMultipartApi,
  securedBaseApi,
  securedMultipartApi,
  softSecuredBaseApi,
};
